import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

import { Get, GetPdfFile } from "../../../services/api";
import { useLoaderContext } from "../../../context/LoaderContext";
import Loader from "../../Loader";

const SalarySlip = () => {
  const { SearchBar } = Search;
  const navigate = useNavigate();
  const { isLoading, setLoading } = useLoaderContext();

  const [currenPage, setCurrentPage] = useState(1);
  const [currenLimit, setCurrentLimit] = useState(10);
  const [currenData, setCurrentData] = useState([]);

  const [errMsg, setErrMsg] = useState("");
  const [errUnAuthorizedMsg, setErrUnAuthorizedMsg] = useState("");

  const columns = [
    {
      dataField: "employee_name",
      text: "Name",
      sort: true,
      headerStyle: { width: "30%" },
      formatter: (cellContent, row) => {
        return row.first_name + " " + row.last_name;
      },
    },
    {
      dataField: "employee_unique_id",
      text: "Emp Id",
      sort: true,
      headerStyle: { width: "10%" },
    },
    {
      dataField: "month_year",
      text: "Month Year",
      sort: true,
      headerStyle: { width: "15%" },
    },
    {
      dataField: "file_name",
      text: "File Name",
      sort: true,
      headerStyle: { width: "35%" },
    },
    {
      dataField: "Action",
      text: "Action",
      classes: "text-center",
      headerStyle: { width: "10%" },
      formatter: (cellContent, row) => {
        return (
          <div className="">
            <button
              className="btn btn-primary"
              onClick={() => handleDownload(row)}
            >
              <i className="fas fa-download"></i>
            </button>
          </div>
        );
      },
    },
  ];
  useEffect(() => {
    document.title = "Salary Slip";
  }, []);

  useEffect(() => {
    if (errMsg !== "") {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: errMsg,
      }).then(function () {
        setErrMsg("");
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [errMsg]);

  useEffect(() => {
    if (errUnAuthorizedMsg !== "") {
      setLoading(false);
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: errUnAuthorizedMsg,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Go Back",
        cancelButtonText: "Login",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then(function (result) {
        setErrUnAuthorizedMsg("");
        setLoading(false);
        if (result.isConfirmed) {
          navigate(-1);
        } else {
          navigate("/login", { replace: true });
        }
      });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [errUnAuthorizedMsg]);

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];
  const pagination = paginationFactory({
    page: currenPage,
    sizePerPage: currenLimit,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      setCurrentPage(sizePerPage);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      setCurrentLimit(page);
    },
  });
  const handleDownload = async (row) => {
    try {
      setLoading(true);
      let payload = {
        id: row.id,
      };
      let response = await GetPdfFile("employee/getSingleSalarySlip", payload);
      if (response.status === 401) {
        setLoading(false);
        setErrUnAuthorizedMsg(response.data);
      } else if (response.status === 404) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Api Not Found!",
        }).then(function () {
          navigate("/employee", { replace: true });
        });
      } else if (response.hasOwnProperty("error")) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: response.data,
        }).then(function () {
          window.location.reload();
        });
      } else {
        setLoading(false);
        const blob = base64ToBlob(response.data.base64Data);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", response.data.file_name);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.message,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Go Back",
        cancelButtonText: "Reload",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then(function (result) {
        setLoading(false);
        if (result.isConfirmed) {
          navigate("/employee");
        } else {
          window.location.reload();
        }
      });
    }
  };

  const base64ToBlob = (base64Data) => {
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray]);
  };

  useEffect(() => {
    async function fetchAPISalarySlipList() {
      try {
        setLoading(true);
        setCurrentData([]);
        let response = await Get("employee/getSalarySlipList");
        if (response.status === 401) {
          setLoading(false);
          setErrUnAuthorizedMsg(response.data);
        } else if (response.status === 404) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Api Not Found!",
          }).then(function () {
            navigate("/employee", { replace: true });
          });
        } else if (response.hasOwnProperty("error")) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: response.data,
          }).then(function () {
            window.location.reload();
          });
        } else {
          setLoading(false);
          setCurrentData(response.data);
        }
      } catch (error) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.message,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: "Go Back",
          cancelButtonText: "Reload",
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then(function (result) {
          setLoading(false);
          if (result.isConfirmed) {
            navigate("/employee");
          } else {
            window.location.reload();
          }
        });
      }
    }
    fetchAPISalarySlipList();
    // eslint-disable-next-line
  }, []);
  const NoDataIndicator = () => (
    <div className="no-data-indicator">No records found</div>
  );

  return (
    <>
      {isLoading && <Loader />}
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">Salary Slip</h1>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <ToolkitProvider
                      bootstrap4
                      keyField="id"
                      data={currenData}
                      columns={columns}
                      search
                    >
                      {(props) => (
                        <div className="row">
                          <div className="col-sm-4">
                            <SearchBar {...props.searchProps} />
                          </div>
                          <div className="">
                            <BootstrapTable
                              {...props.baseProps}
                              pagination={pagination}
                              defaultSorted={defaultSorted}
                              noDataIndication={<NoDataIndicator />}
                              striped
                            />
                          </div>
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default SalarySlip;
