import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Select from "react-select";
import moment from "moment/moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

import { Post, Get, GetPdfFile } from "../../../services/api";
import { useLoaderContext } from "../../../context/LoaderContext";
import Loader from "../../Loader";

const SalarySlip = () => {
  const { SearchBar } = Search;
  const navigate = useNavigate();
  const { isLoading, setLoading } = useLoaderContext();
  const [employeeList, setEmployeeList] = useState([]);
  const [currentEmployee, setCurrentEmployee] = useState({});
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [selectedFile, setSelectedFile] = useState(null);

  const [currenPage, setCurrentPage] = useState(1);
  const [currenLimit, setCurrentLimit] = useState(10);
  const [currenData, setCurrentData] = useState([]);

  const [errMsg, setErrMsg] = useState("");
  const [errUnAuthorizedMsg, setErrUnAuthorizedMsg] = useState("");

  const columns = [
    {
      dataField: "employee_name",
      text: "Employee Name",
      sort: true,
      headerStyle: { width: "25%" },
      formatter: (cellContent, row) => {
        return row.first_name + " " + row.last_name;
      },
    },
    {
      dataField: "employee_unique_id",
      text: "Emp Id",
      sort: true,
      headerStyle: { width: "10%" },
    },
    {
      dataField: "month_year",
      text: "Month Year",
      sort: true,
      headerStyle: { width: "15%" },
    },
    {
      dataField: "file_name",
      text: "File Name",
      sort: true,
      headerStyle: { width: "35%" },
    },
    {
      dataField: "Action",
      text: "Action",
      classes: "text-center",
      headerStyle: { width: "15%" },
      formatter: (cellContent, row) => {
        return (
          <div className="">
            <button
              className="btn btn-primary"
              onClick={() => handleDownload(row)}
            >
              <i className="fas fa-download"></i>
            </button>
            <button
              className="btn btn-danger"
              onClick={() => handleDelete(row)}
            >
              <i className="fas fa-trash"></i>
            </button>
          </div>
        );
      },
    },
  ];
  useEffect(() => {
    document.title = "Salary Slip";
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [currentEmployee, selectedMonth, selectedFile]);

  useEffect(() => {
    if (errMsg !== "") {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: errMsg,
      }).then(function () {
        setErrMsg("");
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [errMsg]);

  useEffect(() => {
    if (errUnAuthorizedMsg !== "") {
      setLoading(false);
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: errUnAuthorizedMsg,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Go Back",
        cancelButtonText: "Login",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then(function (result) {
        setErrUnAuthorizedMsg("");
        setLoading(false);
        if (result.isConfirmed) {
          navigate(-1);
        } else {
          navigate("/admin/login", { replace: true });
        }
      });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [errUnAuthorizedMsg]);

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];
  const pagination = paginationFactory({
    page: currenPage,
    sizePerPage: currenLimit,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      setCurrentPage(sizePerPage);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      setCurrentLimit(page);
    },
  });

  const handleDelete = async (row) => {
    try {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: "Are you sure you want to delete?",
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Delete",
        cancelButtonText: "Cancel",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then(async function (result) {
        if (result.isConfirmed) {
          setLoading(true);
          let payload = { id: row.id };
          let response = await GetPdfFile("admin/deleteSalarySlip", payload);
          if (response.status === 401) {
            setLoading(false);
            setErrUnAuthorizedMsg(response.data);
          } else if (response.status === 404) {
            setLoading(false);
            Swal.fire({
              icon: "error",
              title: "Error",
              text: "Api Not Found!",
            }).then(function () {
              navigate("/admin", { replace: true });
            });
          } else if (response.hasOwnProperty("error")) {
            setLoading(false);
            Swal.fire({
              icon: "error",
              title: "Error",
              text: response.data,
            }).then(function () {
              window.location.reload();
            });
          } else {
            setLoading(false);
            Swal.fire({
              icon: "success",
              title: "Success",
              text: response.data,
            }).then(function () {
              window.location.reload();
            });
          }
        } else {
          window.location.reload();
        }
      });
    } catch (error) {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.message,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Go Back",
        cancelButtonText: "Reload",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then(function (result) {
        setLoading(false);
        if (result.isConfirmed) {
          navigate("/admin");
        } else {
          window.location.reload();
        }
      });
    }
  };

  const handleDownload = async (row) => {
    try {
      setLoading(true);
      let payload = {
        id: row.id,
      };
      let response = await GetPdfFile("admin/getSingleSalarySlip", payload);
      if (response.status === 401) {
        setLoading(false);
        setErrUnAuthorizedMsg(response.data);
      } else if (response.status === 404) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Api Not Found!",
        }).then(function () {
          navigate("/admin", { replace: true });
        });
      } else if (response.hasOwnProperty("error")) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: response.data,
        }).then(function () {
          window.location.reload();
        });
      } else {
        setLoading(false);
        const blob = base64ToBlob(response.data.base64Data);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", response.data.file_name);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.message,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Go Back",
        cancelButtonText: "Reload",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then(function (result) {
        setLoading(false);
        if (result.isConfirmed) {
          navigate("/admin");
        } else {
          window.location.reload();
        }
      });
    }
  };

  const base64ToBlob = (base64Data) => {
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray]);
  };

  useEffect(() => {
    async function fetchAPISalarySlipList() {
      try {
        setLoading(true);
        setCurrentData([]);
        let response = await Get("admin/getSalarySlipList");
        if (response.status === 401) {
          setLoading(false);
          setErrUnAuthorizedMsg(response.data);
        } else if (response.status === 404) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Api Not Found!",
          }).then(function () {
            navigate("/admin", { replace: true });
          });
        } else if (response.hasOwnProperty("error")) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: response.data,
          }).then(function () {
            window.location.reload();
          });
        } else {
          setLoading(false);
          setCurrentData(response.data);
        }
      } catch (error) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.message,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: "Go Back",
          cancelButtonText: "Reload",
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then(function (result) {
          setLoading(false);
          if (result.isConfirmed) {
            navigate("/admin");
          } else {
            window.location.reload();
          }
        });
      }
    }
    fetchAPISalarySlipList();
    // eslint-disable-next-line
  }, []);
  const NoDataIndicator = () => (
    <div className="no-data-indicator">No records found</div>
  );

  useEffect(() => {
    async function fetchAPIEmployeesListForSalarySlip() {
      try {
        setLoading(true);
        setEmployeeList([]);
        const response = await Get("admin/getEmployeesListForSalarySlip");
        if (response.status === 401) {
          setLoading(false);
          setErrUnAuthorizedMsg(response.data);
        } else if (response.status === 404) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Api Not Found!",
          }).then(function () {
            navigate("/admin", { replace: true });
          });
        } else if (response.hasOwnProperty("error")) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: response.data,
          }).then(function () {
            window.location.reload();
          });
        } else {
          setLoading(false);
          // eslint-disable-next-line
          response.data.map((items) => {
            setEmployeeList((employeeList) => [
              ...employeeList,
              {
                label:
                  items.first_name +
                  " " +
                  items.last_name +
                  " - " +
                  items.employee_unique_id,
                value: items.id,
                uniqueId: items.employee_unique_id,
              },
            ]);
          });
        }
      } catch (error) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.message,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: "Go Back",
          cancelButtonText: "Reload",
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then(function (result) {
          setLoading(false);
          if (result.isConfirmed) {
            navigate("/admin");
          } else {
            window.location.reload();
          }
        });
      }
    }
    fetchAPIEmployeesListForSalarySlip();
    // eslint-disable-next-line
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const binaryImage = new Uint8Array(reader.result);
      setSelectedFile(binaryImage);
    };
    if (file) {
      reader.readAsArrayBuffer(file);
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      setErrMsg("");
      if (currentEmployee.value === "" || currentEmployee.value === undefined) {
        setErrMsg("Employee is Require.");
        return;
      }
      if (!selectedFile) {
        setErrMsg("Salary Slip is Require.");
        return;
      }
      if (selectedMonth === "") {
        setErrMsg("Month/Year is require.");
        return;
      }
      let payload = {
        employeeId: currentEmployee.value,
        employeeUniqueId: currentEmployee.uniqueId,
        selectedFile: selectedFile ? Array.from(selectedFile) : null,
        selectedMonth: moment(selectedMonth).format("MM-YYYY"),
      };
      const response = await Post("admin/uploadSalarySlip", payload);
      if (response.status === 401) {
        setLoading(false);
        setErrUnAuthorizedMsg(response.data);
      } else if (response.status === 404) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Api Not Found!",
        }).then(function () {
          navigate("/admin", { replace: true });
        });
      } else if (response.hasOwnProperty("error")) {
        setLoading(false);
        setErrMsg(response.data);
      } else {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data,
        }).then(function () {
          window.location.reload();
        });
      }
    } catch (error) {
      setLoading(false);
      setErrMsg(error.message);
    }
  };
  return (
    <>
      {isLoading && <Loader />}
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">Salary Slip</h1>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="card card-primary">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="employee">Employee</label>
                          <Select
                            id="employee"
                            options={employeeList}
                            value={currentEmployee}
                            onChange={setCurrentEmployee}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="selectMonth">Month/Year</label>
                          <DatePicker
                            className="form-control"
                            onChange={(date) => setSelectedMonth(date)}
                            selected={selectedMonth}
                            dateFormat="MM-yyyy"
                            maxDate={new Date()}
                            showMonthYearPicker
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="selectFile">Select File</label>
                          <input
                            type="file"
                            id="selectFile"
                            onChange={handleFileChange}
                            accept=".pdf"
                            placeholder="Salary Slip"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="salarySlip">Upload Salary Slip</label>
                          <button
                            className="btn btn-success btn-block"
                            onClick={handleSubmit}
                          >
                            Upload
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <ToolkitProvider
                      bootstrap4
                      keyField="id"
                      data={currenData}
                      columns={columns}
                      search
                    >
                      {(props) => (
                        <div className="row">
                          <div className="col-sm-4">
                            <SearchBar {...props.searchProps} />
                          </div>
                          <div className="">
                            <BootstrapTable
                              {...props.baseProps}
                              pagination={pagination}
                              defaultSorted={defaultSorted}
                              noDataIndication={<NoDataIndicator />}
                              striped
                            />
                          </div>
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default SalarySlip;
