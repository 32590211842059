import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";
import { Post, Get } from "../../../services/api";
import { useLoaderContext } from "../../../context/LoaderContext";
import Loader from "../../Loader";

const AdminList = () => {
  const { SearchBar } = Search;
  const navigate = useNavigate();
  const { isLoading, setLoading } = useLoaderContext();
  const [changePassowrdShow, setChangePassowrdShow] = useState(false);
  const [currenAdmin, setCurrentAdmin] = useState({});
  const [password, setPassword] = useState("");
  const [currenPage, setCurrentPage] = useState(1);
  const [currenLimit, setCurrentLimit] = useState(10);
  const [currenData, setCurrentData] = useState([]);
  const [errUnAuthorizedMsg, setErrUnAuthorizedMsg] = useState("");

  useEffect(() => {
    document.title = "Admin List";
  }, []);
  useEffect(() => {
    if (errUnAuthorizedMsg !== "") {
      setLoading(false);
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: errUnAuthorizedMsg,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Go Back",
        cancelButtonText: "Login",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then(function (result) {
        setErrUnAuthorizedMsg("");
        setLoading(false);
        if (result.isConfirmed) {
          navigate(-1);
        } else {
          navigate("/admin/login", { replace: true });
        }
      });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [errUnAuthorizedMsg]);
  const columns = [
    {
      dataField: "user_name",
      text: "User Name",
      sort: true,
    },
    {
      dataField: "first_name",
      text: "First Name",
      sort: true,
    },
    {
      dataField: "last_name",
      text: "Last Name",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
    },
    {
      dataField: "gender",
      text: "Gender",
    },
    {
      dataField: "status",
      text: "Status",
    },
    {
      dataField: "Action",
      text: "Action",
      classes: "text-center",
      formatter: (cellContent, row) => {
        return (
          <div className="">
            <button
              className="btn btn-primary"
              onClick={() => handleUpdate(row)}
            >
              <i className="fas fa-edit"></i>
            </button>
            <button className="btn btn-info" onClick={() => handleView(row)}>
              <i className="fas fa-eye"></i>
            </button>
            <button
              className="btn btn-warning"
              onClick={() => handleChangePassword(row)}
            >
              <i className="fas fa-key"></i>
            </button>
          </div>
        );
      },
    },
  ];
  const handleUpdate = (row) => {
    navigate("/admin/updateAdmin/" + row.id, { replace: true });
  };
  const handleView = (row) => {
    navigate("/admin/viewAdmin/" + row.id, { replace: true });
  };
  const handleChangePassword = (row) => {
    setCurrentAdmin(row);
    setChangePassowrdShow(true);
  };
  const handleChangePasswordClose = () => {
    setChangePassowrdShow(false);
    setCurrentAdmin({});
    setPassword("");
  };
  const handleChangePasswordSubmit = async () => {
    try {
      setLoading(true);
      if (password === "") {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Password Not be blank.",
        });
        return;
      }
      let payload = {
        id: currenAdmin.id,
        password: password,
      };
      const response = await Post("admin/adminChangePassword", payload);
      if (response.status === 401) {
        setLoading(false);
        setErrUnAuthorizedMsg(response.data);
      } else if (response.status === 404) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Api Not Found!",
        }).then(function () {
          navigate("/admin", { replace: true });
        });
      } else if (response.hasOwnProperty("error")) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: response.data,
        }).then(function () {
          setLoading(false);
          window.location.reload();
        });
      } else {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data,
        }).then(function () {
          setLoading(false);
          window.location.reload();
        });
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.message,
      }).then(function () {
        setLoading(false);
        window.location.reload();
      });
    }
  };
  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];
  const pagination = paginationFactory({
    page: currenPage,
    sizePerPage: currenLimit,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      setCurrentPage(sizePerPage);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      setCurrentLimit(page);
    },
  });
  useEffect(() => {
    async function fetchAPIAdminList() {
      try {
        setLoading(true);
        const response = await Get("admin/getAdminList");
        if (response.status === 401) {
          setLoading(false);
          setErrUnAuthorizedMsg(response.data);
        } else if (response.status === 404) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Api Not Found!",
          }).then(function () {
            navigate("/admin", { replace: true });
          });
        } else if (response.hasOwnProperty("error")) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: response.data,
          }).then(function () {
            window.location.reload();
          });
        } else {
          setLoading(false);
          setCurrentData(response.data);
        }
      } catch (error) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.message,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: "Go Back",
          cancelButtonText: "Reload",
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then(function (result) {
          setLoading(false);
          if (result.isConfirmed) {
            navigate("/admin");
          } else {
            window.location.reload();
          }
        });
      }
    }
    fetchAPIAdminList();
    // eslint-disable-next-line
  }, []);
  const NoDataIndicator = () => (
    <div className="no-data-indicator">No records found</div>
  );
  return (
    <>
      {isLoading && <Loader />}
      <div className="content-wrapper">
        <Modal show={changePassowrdShow} onHide={handleChangePasswordClose}>
          <Modal.Header closeButton>
            <Modal.Title>Change Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input
                type="text"
                id="password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                placeholder="Password"
                className="form-control"
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-danger"
              onClick={handleChangePasswordClose}
            >
              Cancel
            </button>
            <button
              className="btn btn-success"
              onClick={handleChangePasswordSubmit}
            >
              Submit
            </button>
          </Modal.Footer>
        </Modal>
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">Admin List</h1>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <ToolkitProvider
                      bootstrap4
                      keyField="id"
                      data={currenData}
                      columns={columns}
                      search
                    >
                      {(props) => (
                        <div className="row">
                          <div className="col-sm-4">
                            <SearchBar {...props.searchProps} />
                          </div>
                          <div className="col-sm-8 text-right">
                            <Link
                              to={"/admin/addAdmin"}
                              className="btn btn-success m-1"
                            >
                              Add Admin
                            </Link>
                          </div>
                          <div className="">
                            <BootstrapTable
                              {...props.baseProps}
                              pagination={pagination}
                              defaultSorted={defaultSorted}
                              noDataIndication={<NoDataIndicator />}
                              striped
                            />
                          </div>
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default AdminList;
