import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import Swal from "sweetalert2";
import Select from "react-select";
import { Post } from "../../../services/api";
import { useLoaderContext } from "../../../context/LoaderContext";
import Loader from "../../Loader";

const UpdateLeaveType = () => {
  const navigate = useNavigate();
  const { isLoading, setLoading } = useLoaderContext();

  const [id, setId] = useState("");
  const [leaveType, setLeaveType] = useState("");
  const [desc, setDesc] = useState("");
  const [totalLeave, setTotalLeave] = useState(0.0);
  const [monthly, setMonthly] = useState(0.0);
  const [biMonthly, setBiMonthly] = useState(0.0);
  const [quarterlyMonths, setQuarterlyMonths] = useState(0.0);
  const [errMsg, setErrMsg] = useState("");
  const [errUnAuthorizedMsg, setErrUnAuthorizedMsg] = useState("");
  const [status, setStatus] = useState({});
  const statusOptions = [
    { value: "Active", label: "Active" },
    { value: "In Active", label: "In Active" },
  ];
  const params = useParams();

  useEffect(() => {
    document.title = "Update Leave Type";
  }, []);
  useEffect(() => {
    if (errMsg !== "") {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: errMsg,
      }).then(function () {
        setErrMsg("");
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [errMsg]);

  useEffect(() => {
    if (errUnAuthorizedMsg !== "") {
      setLoading(false);
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: errUnAuthorizedMsg,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Go Back",
        cancelButtonText: "Login",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then(function (result) {
        setErrUnAuthorizedMsg("");
        setLoading(false);
        if (result.isConfirmed) {
          navigate(-1);
        } else {
          navigate("/admin/login", { replace: true });
        }
      });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [errUnAuthorizedMsg]);

  useEffect(() => {
    setErrMsg("");
  }, [leaveType, desc, totalLeave, status]);

  useEffect(() => {
    async function fetchAPISingleLeaveType() {
      try {
        setLoading(true);
        let payload = { id: params.id };
        let response = await Post("admin/getSingleLeaveType", payload);
        if (response.status === 401) {
          setLoading(false);
          setErrUnAuthorizedMsg(response.data);
        } else if (response.status === 404) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Api Not Found!",
          }).then(function () {
            navigate("/admin/leaveTypeList", { replace: true });
          });
        } else if (response.hasOwnProperty("error")) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: response.data,
          }).then(function () {
            window.location.reload();
          });
        } else {
          setId(response.data[0].id);
          setLeaveType(response.data[0].leave_type);
          setDesc(
            response.data[0].description
              ? response.data[0].description.replace(/----/g, "'")
              : ""
          );
          setTotalLeave(response.data[0].total_leave);
          setMonthly(response.data[0].monthly);
          setBiMonthly(response.data[0].bi_monthly);
          setQuarterlyMonths(response.data[0].quarterly_months);
          if (response.data[0].status === "Active") {
            setStatus({ value: "Active", label: "Active" });
          } else {
            setStatus({ value: "In Active", label: "In Active" });
          }
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.message,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: "Go Back",
          cancelButtonText: "Reload",
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then(function (result) {
          setLoading(false);
          if (result.isConfirmed) {
            navigate("/admin/leaveTypeList");
          } else {
            window.location.reload();
          }
        });
      }
    }
    fetchAPISingleLeaveType();
    // eslint-disable-next-line
  }, [params]);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      setErrMsg("");
      if (leaveType === "") {
        setErrMsg("Leave Type is require.");
        return;
      }

      if (status.value === "" || status.value === undefined) {
        setErrMsg("Status is require.");
        return;
      }
      let payload = {
        id: id,
        leaveType: leaveType,
        desc: desc,
        totalLeave: totalLeave === "" ? 0 : totalLeave,
        monthly: monthly === "" ? 0 : monthly,
        biMonthly: biMonthly === "" ? 0 : biMonthly,
        quarterlyMonths: quarterlyMonths === "" ? 0 : quarterlyMonths,
        status: status.value,
      };
      const response = await Post("admin/updateLeaveType", payload);
      if (response.status === 401) {
        setLoading(false);
        setErrUnAuthorizedMsg(response.data);
      } else if (response.status === 404) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Api Not Found!",
        }).then(function () {
          navigate("/admin/leaveTypeList", { replace: true });
        });
      } else if (response.hasOwnProperty("error")) {
        setLoading(false);
        setErrMsg(response.data);
      } else {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data,
        }).then(function () {
          navigate("/admin/leaveTypeList", { replace: true });
        });
      }
    } catch (error) {
      setLoading(false);
      setErrMsg(error.message);
    }
  };

  const handleTotalChange = (e) => {
    const value = e.target.value;
    if (/^-?\d*\.?\d*$/.test(value)) {
      setTotalLeave(value);
    }
  };
  const handleMonthly = (e) => {
    const value = e.target.value;
    if (/^-?\d*\.?\d*$/.test(value)) {
      setMonthly(value);
    }
  };
  const handleBiMonthly = (e) => {
    const value = e.target.value;
    if (/^-?\d*\.?\d*$/.test(value)) {
      setBiMonthly(value);
    }
  };
  const handleQuarterlyMonths = (e) => {
    const value = e.target.value;
    if (/^-?\d*\.?\d*$/.test(value)) {
      setQuarterlyMonths(value);
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">Update Leave Type</h1>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="card card-primary">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="leaveType">Leave Type</label>
                          <input
                            type="text"
                            id="leaveType"
                            onChange={(e) => setLeaveType(e.target.value)}
                            value={leaveType}
                            placeholder="Leave Type"
                            required
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="description">Description</label>
                          <input
                            type="text"
                            id="description"
                            onChange={(e) => setDesc(e.target.value)}
                            value={desc}
                            placeholder="Description"
                            required
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="totalleave">Total Leave</label>
                          <input
                            type="text"
                            id="totalleave"
                            onChange={handleTotalChange}
                            value={totalLeave}
                            placeholder="Total leave"
                            required
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="status">Status</label>
                          <Select
                            id="status"
                            options={statusOptions}
                            value={status}
                            onChange={setStatus}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="monthly">Monthly</label>
                          <input
                            type="text"
                            id="monthly"
                            onChange={handleMonthly}
                            value={monthly}
                            placeholder="Monthly"
                            required
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="biMonthly">BiMonthly</label>
                          <input
                            type="text"
                            id="biMonthly"
                            onChange={handleBiMonthly}
                            value={biMonthly}
                            placeholder="BiMonthly"
                            required
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="quarterlyMonths">
                            Quarterly Months
                          </label>
                          <input
                            type="text"
                            id="quarterlyMonths"
                            onChange={handleQuarterlyMonths}
                            value={quarterlyMonths}
                            placeholder="Quarterly Months"
                            required
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-md-3 m-2">
                        <button
                          className="btn btn-success btn-block"
                          onClick={handleSubmit}
                        >
                          Update Leave type
                        </button>
                      </div>
                      <div className="col-md-3 m-2">
                        <Link
                          to={"/admin/leaveTypeList"}
                          className="btn btn-danger btn-block"
                        >
                          Cancel
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default UpdateLeaveType;
