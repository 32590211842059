import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import Swal from "sweetalert2";
import { Post } from "../../../services/api";
import { useLoaderContext } from "../../../context/LoaderContext";
import Loader from "../../Loader";

const UpdateDepartment = () => {
  const navigate = useNavigate();
  const { isLoading, setLoading } = useLoaderContext();

  const [departmentName, setDepartmentName] = useState("");
  const [departmentShortName, setDepartmentShortName] = useState("");
  const [departmentCode, setDepartmentCode] = useState("");
  const [errUnAuthorizedMsg, setErrUnAuthorizedMsg] = useState("");
  const [status, setStatus] = useState("");

  const params = useParams();

  useEffect(() => {
    document.title = "View Department";
  }, []);
  useEffect(() => {
    if (errUnAuthorizedMsg !== "") {
      setLoading(false);
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: errUnAuthorizedMsg,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Go Back",
        cancelButtonText: "Login",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then(function (result) {
        setErrUnAuthorizedMsg("");
        setLoading(false);
        if (result.isConfirmed) {
          navigate(-1);
        } else {
          navigate("/admin/login", { replace: true });
        }
      });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [errUnAuthorizedMsg]);

  useEffect(() => {
    async function fetchAPISingleDepartment() {
      try {
        setLoading(true);
        let payload = { id: params.id };
        let response = await Post("admin/getSingleDepartment", payload);
        if (response.status === 401) {
          setLoading(false);
          setErrUnAuthorizedMsg(response.data);
        } else if (response.status === 404) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Api Not Found!",
          }).then(function () {
            navigate("/admin/departmentList", { replace: true });
          });
        } else if (response.hasOwnProperty("error")) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: response.data,
          }).then(function () {
            window.location.reload();
          });
        } else {
          setDepartmentName(response.data[0].department_name);
          setDepartmentShortName(response.data[0].department_short_name);
          setDepartmentCode(response.data[0].department_code);
          setStatus(response.data[0].status);

          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.message,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: "Go Back",
          cancelButtonText: "Reload",
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then(function (result) {
          setLoading(false);
          if (result.isConfirmed) {
            navigate("/admin/departmentList");
          } else {
            window.location.reload();
          }
        });
      }
    }
    fetchAPISingleDepartment();
    // eslint-disable-next-line
  }, [params]);

  return (
    <>
      {isLoading && <Loader />}
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">View Department</h1>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="card card-primary">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="departmentName">
                            Department Name
                          </label>
                          <input
                            type="text"
                            id="departmentName"
                            value={departmentName}
                            placeholder="Department Name"
                            disabled
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="departmentShortName">
                            Department Short Name
                          </label>
                          <input
                            type="text"
                            id="departmentShortName"
                            value={departmentShortName}
                            placeholder="Department Short Name"
                            disabled
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="departmentCode">
                            Department Code
                          </label>
                          <input
                            type="text"
                            id="departmentCode"
                            value={departmentCode}
                            placeholder="Department Code"
                            disabled
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="status">Status</label>
                          <input
                            type="text"
                            id="status"
                            value={status}
                            placeholder="Status"
                            disabled
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-md-3 m-2">
                        <Link
                          to={"/admin/departmentList"}
                          className="btn btn-block btn-default"
                        >
                          Back
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default UpdateDepartment;
