import React from "react";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <nav
      className="main-header navbar navbar-expand navbar-white navbar-light"
      id="header"
    >
      <ul className="navbar-nav">
        <li className="nav-item">
          <Link className="nav-link" data-widget="pushmenu" role="button">
            <i className="fas fa-bars"></i>
          </Link>
        </li>
      </ul>
      <ul className="navbar-nav ml-auto">
        <li className="nav-item dropdown">
          <Link className="nav-link" data-toggle="dropdown">
            <i className="far fa-user" />
          </Link>
          <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
            <div className="dropdown-divider" />
            <Link className="dropdown-item" to={"/admin/profileView"}>
              <i className="fas fa-address-card mr-2" /> Profile
            </Link>
            <Link className="dropdown-item" to={"/admin/changePassword"}>
              <i className="fas fa-key mr-2" /> Change Password
            </Link>
            <Link className="dropdown-item" to={"/admin/login"} replace>
              <i className="fas fa-sign-out-alt mr-2" /> LogOut
            </Link>
          </div>
        </li>
      </ul>
    </nav>
  );
};

export default Header;
