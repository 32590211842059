import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../logo.png";
import { getCurrentUser } from "../../services/api";

const Sidebar = () => {
  const location = useLocation();
  const navItemRef = useRef(null);
  const [currentLocation, setCurrentLocation] = useState({});
  const [user, setUser] = useState({});
  const [isHide, setIsHide] = useState(true);

  const currentId = location.pathname.split("/").pop();
  useEffect(() => {
    setUser(getCurrentUser());
    setCurrentLocation(location.pathname);
    // eslint-disable-next-line
  }, [location]);
  const handleSubMenu = () => {
    const classes = ["menu-is-opening", "menu-open"];
    const classList = navItemRef.current.classList;
    const shouldRemoveClasses = classes.some((className) =>
      classList.contains(className)
    );
    if (shouldRemoveClasses) {
      setIsHide(true);
      classes.forEach((className) => classList.remove(className));
    } else {
      setIsHide(false);
      classes.forEach((className) => classList.add(className));
    }
  };
  return (
    <aside
      className="main-sidebar sidebar-dark-primary elevation-4"
      id="sidebar"
    >
      <div className="brand-link">
        <img
          src={logo}
          className="brand-image img-circle elevation-2"
          alt="admin"
          style={{ height: "33px", width: "44px" }}
        />
        <span
          className="brand-text font-weight-light"
          style={{ color: "#fff" }}
        >
          {user?.userName || "username"}
        </span>
      </div>
      <div className="sidebar">
        <div className="os-padding">
          <div
            className="os-viewport os-viewport-native-scrollbars-invisible"
            style={{ overflowY: "scroll" }}
          >
            <div className="os-content">
              <nav className="mt-2">
                <ul
                  className="nav nav-pills nav-sidebar flex-column"
                  data-widget="treeview"
                  role="menu"
                  data-accordion="false"
                >
                  <li className="nav-item">
                    <Link
                      to={"/admin"}
                      className={`nav-link ${
                        currentLocation === "/admin" ? "active" : ""
                      }`}
                    >
                      <i className="nav-icon fas fa-tachometer-alt" />
                      <p>DashBoard</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to={"/admin/adminList"}
                      className={`nav-link ${
                        currentLocation === "/admin/adminList" ||
                        currentLocation === "/admin/addAdmin" ||
                        currentLocation === "/admin/updateAdmin/" + currentId ||
                        currentLocation === "/admin/viewAdmin/" + currentId
                          ? "active"
                          : ""
                      }`}
                    >
                      <i className="nav-icon fas fa-users" />
                      <p>Admin</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to={"/admin/employeeList"}
                      className={`nav-link ${
                        currentLocation === "/admin/employeeList" ||
                        currentLocation === "/admin/addEmployee" ||
                        currentLocation ===
                          "/admin/updateEmployee/" + currentId ||
                        currentLocation === "/admin/viewEmployee/" + currentId
                          ? "active"
                          : ""
                      }`}
                    >
                      <i className="nav-icon fas fa-users" />
                      <p>Employees</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to={"/admin/departmentList"}
                      className={`nav-link ${
                        currentLocation === "/admin/departmentList" ||
                        currentLocation === "/admin/addDepartment" ||
                        currentLocation ===
                          "/admin/updateDepartment/" + currentId ||
                        currentLocation === "/admin/viewDepartment/" + currentId
                          ? "active"
                          : ""
                      }`}
                    >
                      <i className="nav-icon fas fa-apartment" />
                      <p>Department</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to={"/admin/holidayList"}
                      className={`nav-link ${
                        currentLocation === "/admin/holidayList" ||
                        currentLocation === "/admin/addHoliday" ||
                        currentLocation ===
                          "/admin/updateHoliday/" + currentId ||
                        currentLocation === "/admin/viewHoliday/" + currentId
                          ? "active"
                          : ""
                      }`}
                    >
                      <i className="nav-icon fas fa-gift" />
                      <p>Holiday</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to={"/admin/leaveTypeList"}
                      className={`nav-link ${
                        currentLocation === "/admin/leaveTypeList" ||
                        currentLocation === "/admin/addLeaveType" ||
                        currentLocation ===
                          "/admin/updateLeaveType/" + currentId ||
                        currentLocation === "/admin/viewLeaveType/" + currentId
                          ? "active"
                          : ""
                      }`}
                    >
                      <i className="nav-icon fas fa-right-left" />
                      <p>Leave Type</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to={"/admin/timeSheetList"}
                      className={`nav-link ${
                        currentLocation === "/admin/timeSheetList"
                          ? "active"
                          : ""
                      }`}
                    >
                      <i className="nav-icon fas fa-file-lines" />
                      <p>TimeSheet</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to={"/admin/salarySlip"}
                      className={`nav-link ${
                        currentLocation === "/admin/salarySlip" ? "active" : ""
                      }`}
                    >
                      <i className="nav-icon fas fa-file-invoice-dollar" />
                      <p>Salary Slip</p>
                    </Link>
                  </li>
                  <li
                    ref={navItemRef}
                    className="nav-item outer"
                    onClick={handleSubMenu}
                  >
                    <Link
                      className={`nav-link ${
                        currentLocation ===
                          "/admin/leaveDetails/" + currentId ||
                        currentLocation ===
                          "/admin/updateLeaveDetails/" + currentId ||
                        currentLocation === "/admin/applyLeave" ||
                        currentLocation === "/admin/balanceLeave" ||
                        currentLocation === "/admin/allLeaves" ||
                        currentLocation === "/admin/pendingLeaves" ||
                        currentLocation === "/admin/approvedLeaves" ||
                        currentLocation === "/admin/notApprovedleaves"
                          ? "active"
                          : ""
                      }`}
                    >
                      <i className="nav-icon fa-light fa-display" />
                      <p>
                        Leave Management
                        <i className="fas fa-angle-left right" />
                      </p>
                    </Link>
                    <ul
                      className="nav nav-treeview"
                      style={{ display: isHide ? "none" : "block" }}
                    >
                      <li className="nav-item">
                        <Link
                          to={"/admin/applyLeave"}
                          className={`nav-link ${
                            currentLocation === "/admin/applyLeave"
                              ? "active"
                              : ""
                          }`}
                        >
                          <p>Apply Leaves</p>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to={"/admin/balanceLeave"}
                          className={`nav-link ${
                            currentLocation === "/admin/balanceLeave"
                              ? "active"
                              : ""
                          }`}
                        >
                          <p>Balance Leaves</p>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to={"/admin/allLeaves"}
                          className={`nav-link ${
                            currentLocation === "/admin/allLeaves"
                              ? "active"
                              : ""
                          }`}
                        >
                          <p>All Leaves</p>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to={"/admin/pendingLeaves"}
                          className={`nav-link ${
                            currentLocation === "/admin/pendingLeaves"
                              ? "active"
                              : ""
                          }`}
                        >
                          <p>Pending Leaves</p>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to={"/admin/approvedLeaves"}
                          className={`nav-link ${
                            currentLocation === "/admin/approvedLeaves"
                              ? "active"
                              : ""
                          }`}
                        >
                          <p>Approved Leaves</p>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to={"/admin/notApprovedleaves"}
                          className={`nav-link ${
                            currentLocation === "/admin/notApprovedleaves"
                              ? "active"
                              : ""
                          }`}
                        >
                          <p>Not Approved Leaves</p>
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
