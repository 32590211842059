import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Select from "react-select";
import moment from "moment/moment";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";

import { Post, getCurrentUser } from "../../../services/api";
import { useLoaderContext } from "../../../context/LoaderContext";
import Loader from "../../Loader";

const TimeSheetList = () => {
  const navigate = useNavigate();
  const { ExportCSVButton } = CSVExport;
  const { isLoading, setLoading } = useLoaderContext();
  const [currenPage, setCurrentPage] = useState(1);
  const [currenLimit, setCurrentLimit] = useState(10);
  const [exportFileName, setExportFileName] = useState("");
  const [currenData, setCurrentData] = useState([]);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [showPicker, setShowPicker] = useState(false);
  const [filter, setFilter] = useState({});
  const filterOption = [
    { value: "week", label: "Last Week" },
    { value: "month", label: "Last Month" },
    { value: "custom", label: "Custom Range" },
  ];

  const [errUnAuthorizedMsg, setErrUnAuthorizedMsg] = useState("");

  useEffect(() => {
    document.title = "TimeSheet List";
  }, []);
  useEffect(() => {
    if (errUnAuthorizedMsg !== "") {
      setLoading(false);
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: errUnAuthorizedMsg,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Go Back",
        cancelButtonText: "Login",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then(function (result) {
        setErrUnAuthorizedMsg("");
        setLoading(false);
        if (result.isConfirmed) {
          navigate(-1);
        } else {
          navigate("/login", { replace: true });
        }
      });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [errUnAuthorizedMsg]);

  useEffect(() => {
    async function fetchAPITimeSheetList() {
      await loadData("none");
    }
    fetchAPITimeSheetList();
    // eslint-disable-next-line
  }, []);
  const columns = [
    {
      dataField: "date",
      text: "Date",
      sort: true,
      sortValue: (cellContent, row) => {
        return moment(row.date).format("YYYY-MM-DD");
      },
      formatter: (cellContent, row) => {
        return moment(row.date).format("YYYY-MM-DD");
      },
    },
    {
      dataField: "in_time",
      text: "In Time",
      formatter: (cellContent, row) => {
        if (row.in_time === "00:00:00") {
          return row.in_time;
        } else {
          return moment(row.in_time, "HH:mm:ss").format("hh:mm A");
        }
      },
    },
    {
      dataField: "out_time",
      text: "Out Time",
      formatter: (cellContent, row) => {
        if (row.out_time === "00:00:00") {
          return row.out_time;
        } else {
          return moment(row.out_time, "HH:mm:ss").format("hh:mm A");
        }
      },
    },
    {
      dataField: "work_time",
      text: "Work Time",
      formatter: (cellContent, row) => {
        if (row.work_time === "00:00:00") {
          return row.work_time;
        } else {
          return `${moment.duration(row.work_time).hours()} Hours ${moment
            .duration(row.work_time)
            .minutes()} Minutes`;
        }
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "date",
      order: "desc",
    },
  ];
  const pagination = paginationFactory({
    page: currenPage,
    sizePerPage: currenLimit,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      setCurrentPage(sizePerPage);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      setCurrentLimit(page);
    },
  });

  const NoDataIndicator = () => (
    <div className="no-data-indicator">No records found</div>
  );

  const setFilterDateRange = async (e) => {
    setShowPicker(false);
    if (e.value === "custom") {
      setShowPicker(true);
    } else if (e.value === "") {
      await loadData("none");
    } else {
      await loadData(e.value);
    }
    setFilter(e);
  };

  const setFileNameFunc = (payload) => {
    let fileName = getCurrentUser()?.userName || "User";
    let ranges = "";
    if (payload.range === "custom") {
      ranges =
        "_From_" +
        moment(payload.startDate).format("YYYY-MM-DD") +
        "_To_" +
        moment(payload.endDate).format("YYYY-MM-DD") +
        "_TimeSheet.csv";
    } else if (payload.range === "week") {
      ranges =
        "_From_" +
        moment()
          .clone()
          .subtract(1, "week")
          .startOf("isoWeek")
          .format("YYYY-MM-DD") +
        "_To_" +
        moment()
          .clone()
          .subtract(1, "week")
          .endOf("isoWeek")
          .format("YYYY-MM-DD") +
        "_TimeSheet.csv";
    } else if (payload.range === "month") {
      ranges =
        "_From_" +
        moment()
          .clone()
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD") +
        "_To_" +
        moment()
          .clone()
          .subtract(1, "month")
          .endOf("month")
          .format("YYYY-MM-DD") +
        "_TimeSheet.csv";
    } else {
      ranges =
        "_From_" +
        moment().startOf("month").format("YYYY-MM-DD") +
        "_To_" +
        moment().format("YYYY-MM-DD") +
        "_TimeSheet.csv";
    }
    setExportFileName(fileName + ranges);
  };

  const loadData = async (range) => {
    try {
      setLoading(true);
      setCurrentData([]);
      let payload = {
        range: range,
        startDate: moment(dateRange[0].startDate).format("YYYY-MM-DD"),
        endDate: moment(dateRange[0].endDate).format("YYYY-MM-DD"),
      };
      setFileNameFunc(payload);
      let response = await Post("employee/getTimeSheetList", payload);
      if (response.status === 401) {
        setLoading(false);
        setErrUnAuthorizedMsg(response.data);
      } else if (response.status === 404) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Api Not Found!",
        }).then(function () {
          navigate("/employee", { replace: true });
        });
      } else if (response.hasOwnProperty("error")) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: response.data,
        }).then(function () {
          window.location.reload();
        });
      } else {
        setLoading(false);
        if (response.data.length > 0) {
          // eslint-disable-next-line
          response.data.map((item) => {
            setCurrentData((currenData) => [
              ...currenData,
              {
                ...item,
                date: moment(item.date).format("YYYY-MM-DD"),
              },
            ]);
          });
        }
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.message,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Go Back",
        cancelButtonText: "Reload",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then(function (result) {
        setLoading(false);
        if (result.isConfirmed) {
          navigate("/employee");
        } else {
          window.location.reload();
        }
      });
    }
  };
  const handleDateChange = (ranges) => {
    setDateRange([ranges.selection]);
  };
  const onRangeSelect = async () => {
    await loadData("custom");
    setShowPicker(false);
  };
  const onRangeSelectClear = async () => {
    await loadData("none");
    setFilter({});
    setShowPicker(false);
  };
  const customStyles = {
    calendar: {
      backgroundColor: "white",
    },
  };
  return (
    <>
      {isLoading && <Loader />}
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">TimeSheet List</h1>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <ToolkitProvider
                      bootstrap4
                      keyField="id"
                      data={currenData}
                      columns={columns}
                      exportCSV={{
                        fileName: exportFileName,
                        ignoreHeader: false,
                        noAutoBOM: false,
                      }}
                    >
                      {(props) => (
                        <div>
                          <div className="row m-1">
                            <div className="col-md-2 offset-md-8">
                              {showPicker && (
                                <div
                                  className="shadow d-inline-block"
                                  style={{
                                    position: "absolute",
                                    top: "50px",
                                    right: "0px",
                                    background: "white",
                                    zIndex: 9999,
                                  }}
                                >
                                  <DateRange
                                    onChange={handleDateChange}
                                    ranges={dateRange}
                                    maxDate={new Date()}
                                    styles={customStyles}
                                  />
                                  <button
                                    className="btn btn-success m-2"
                                    onClick={onRangeSelect}
                                  >
                                    Apply
                                  </button>
                                  <button
                                    className="btn btn-danger m-2"
                                    onClick={onRangeSelectClear}
                                  >
                                    Clear
                                  </button>
                                </div>
                              )}
                              {currenData.length > 0 && (
                                <ExportCSVButton
                                  {...props.csvProps}
                                  className="m-1"
                                >
                                  Export to Excel
                                </ExportCSVButton>
                              )}
                            </div>
                            <div className="col-md-2">
                              <Select
                                id="filter"
                                options={filterOption}
                                value={filter}
                                onChange={setFilterDateRange}
                              />
                            </div>
                          </div>
                          <div className="">
                            <BootstrapTable
                              {...props.baseProps}
                              pagination={pagination}
                              defaultSorted={defaultSorted}
                              noDataIndication={<NoDataIndicator />}
                              striped
                            />
                          </div>
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default TimeSheetList;
