import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import Swal from "sweetalert2";
import moment from "moment/moment";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

import { Get, GetProfileImage } from "../../services/api";
import { useLoaderContext } from "../../context/LoaderContext";
import Loader from "../Loader";

const ProfileView = () => {
  const navigate = useNavigate();
  const { isLoading, setLoading } = useLoaderContext();
  const params = useParams();

  const [imageUrl, setImageUrl] = useState("");
  const [employeeUniqueId, setEmployeeUniqueId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [dob, setDob] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [pincode, setPincode] = useState("");
  const [currentDepartment, setCurrentDepartment] = useState("");
  const [gender, setGender] = useState("");
  const [joiningDate, setJoiningDate] = useState("");
  const [anniversaryDate, setAnniversaryDate] = useState("");
  const [emergencyContact, setEmergencyContact] = useState("");
  const [bloodGroup, setBloodGroup] = useState("");

  const [errMsg, setErrMsg] = useState("");
  const [errUnAuthorizedMsg, setErrUnAuthorizedMsg] = useState("");

  useEffect(() => {
    document.title = "Profile View";
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [
    employeeUniqueId,
    firstName,
    lastName,
    email,
    dob,
    address,
    city,
    state,
    country,
    mobileNo,
    currentDepartment,
    gender,
    joiningDate,
    anniversaryDate,
    bloodGroup,
    emergencyContact,
    pincode,
  ]);

  useEffect(() => {
    if (errMsg !== "") {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: errMsg,
      }).then(function () {
        setErrMsg("");
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [errMsg]);

  useEffect(() => {
    if (errUnAuthorizedMsg !== "") {
      setLoading(false);
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: errUnAuthorizedMsg,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Go Back",
        cancelButtonText: "Login",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then(function (result) {
        setErrUnAuthorizedMsg("");
        setLoading(false);
        if (result.isConfirmed) {
          navigate(-1);
        } else {
          navigate("/login", { replace: true });
        }
      });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [errUnAuthorizedMsg]);

  useEffect(() => {
    async function fetchAPIGetProfile() {
      try {
        setLoading(true);
        let response = await Get("employee/getProfile");
        if (response.status === 401) {
          setLoading(false);
          setErrUnAuthorizedMsg(response.data);
        } else if (response.status === 404) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Api Not Found!",
          }).then(function () {
            navigate("/employee", { replace: true });
          });
        } else if (response.hasOwnProperty("error")) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: response.data,
          }).then(function () {
            window.location.reload();
          });
        } else {
          setEmployeeUniqueId(response.data[0].employee_unique_id);
          setFirstName(response.data[0].first_name);
          setLastName(response.data[0].last_name);
          setEmail(response.data[0].email);
          setMobileNo(response.data[0].mobile_no);
          setPincode(response.data[0].pincode);
          setAddress(
            response.data[0].address
              ? response.data[0].address.replace(/----/g, "'")
              : ""
          );
          setCity(response.data[0].city);
          setState(response.data[0].state);
          setCountry(response.data[0].country);
          setDob(
            response.data[0].dob === null || response.data[0].dob === ""
              ? ""
              : moment(response.data[0].dob).format("YYYY-MM-DD")
          );
          setGender(response.data[0].gender);
          setCurrentDepartment(response.data[0].department);
          setJoiningDate(
            response.data[0].joining_date === null ||
              response.data[0].joining_date === ""
              ? ""
              : moment(response.data[0].joining_date).format("YYYY-MM-DD")
          );
          setAnniversaryDate(
            response.data[0].anniversary_date === null ||
              response.data[0].anniversary_date === ""
              ? ""
              : moment(response.data[0].anniversary_date).format("YYYY-MM-DD")
          );
          setBloodGroup(response.data[0].blood_group);
          setEmergencyContact(response.data[0].emergency_contact);
          let pImgT = await GetProfileImage();
          if (!pImgT.hasOwnProperty("error")) {
            if (pImgT) {
              setImageUrl(`data:image/jpeg;base64,${pImgT}`);
            }
          }
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.message,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: "Go Back",
          cancelButtonText: "Reload",
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then(function (result) {
          setLoading(false);
          if (result.isConfirmed) {
            navigate("/employee");
          } else {
            window.location.reload();
          }
        });
      }
    }
    fetchAPIGetProfile();
    // eslint-disable-next-line
  }, [params]);

  return (
    <>
      {isLoading && <Loader />}
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">Profile View</h1>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-3">
                <div className="card card-primary card-outline">
                  <div className="card-body box-profile">
                    <div className="text-center">
                      {imageUrl !== "" ? (
                        <img
                          className="profile-user-img img-fluid img-circle custom-image"
                          alt="User profile"
                          src={imageUrl}
                        ></img>
                      ) : (
                        <i className="fas fa-user custom-icon"></i>
                      )}

                      <h3 className="profile-username text-center">
                        {firstName} {lastName}
                      </h3>
                      <span className="text-muted text-center">
                        {employeeUniqueId}
                      </span>
                      <p className="text-muted text-center">
                        {currentDepartment}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Professional Info</h3>
                  </div>
                  <div className="card-body">
                    <strong>
                      <i className="fa-sharp fa-solid fa-envelope mr-1"></i>
                      &nbsp; Email
                    </strong>
                    <p className="text-muted">{email}</p>
                    <strong>
                      <i className="fa-sharp fa-solid fa-phone mr-1"></i>
                      &nbsp; Mobile
                    </strong>
                    <p className="text-muted">{mobileNo}</p>
                    <strong>
                      <i className="fa-sharp fa-solid fa-phone-volume mr-1"></i>
                      &nbsp; Emergency Contact
                    </strong>
                    <p className="text-muted">{emergencyContact}</p>
                    <strong>
                      <i className="fa-sharp fa-solid fa-clock mr-1"></i>
                      &nbsp; Joining Date
                    </strong>
                    <p className="text-muted">
                      {joiningDate ? joiningDate : "---"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Personal Info</h3>
                  </div>
                  <div className="card-body">
                    <strong>
                      <i className="fa-sharp fa-solid fa-venus-mars mr-1"></i>
                      &nbsp; Gender
                    </strong>
                    <p className="text-muted">{gender}</p>
                    <strong>
                      <i className="fa-sharp fa-solid fa-cake-candles mr-1"></i>
                      &nbsp; Date of Birth
                    </strong>
                    <p className="text-muted">{dob ? dob : "---"}</p>
                    <strong>
                      <i className="fa-duotone fa-cake-candles mr-1"></i>
                      &nbsp; Anniversary
                    </strong>
                    <p className="text-muted">
                      {anniversaryDate ? anniversaryDate : "---"}
                    </p>
                    <strong>
                      <i className="fa-sharp fa-solid fa-hand-holding-droplet mr-1"></i>
                      &nbsp; Blood Group
                    </strong>
                    <p className="text-muted">
                      {bloodGroup ? bloodGroup : "---"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">
                      <i className="fa-sharp fa-solid fa-location-dot mr-1"></i>
                      &nbsp; Address
                    </h3>
                  </div>
                  <div className="card-body">
                    <strong>
                      <i className="fa-sharp fa-solid fa-address-book mr-1"></i>
                      &nbsp; Address
                    </strong>
                    <p className="text-muted">
                      {address},{city},{state},{country},{pincode}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="row justify-content-center">
                  <div className="col-md-3 m-2">
                    <Link
                      to={"/employee/profile"}
                      className="btn btn-success btn-block"
                    >
                      Edit Profile
                    </Link>
                  </div>
                  <div className="col-md-3 m-2">
                    <Link
                      to={"/employee"}
                      className="btn btn-block btn-default"
                    >
                      Back
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default ProfileView;
