import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Swal from "sweetalert2";
import moment from "moment/moment";

import { Get } from "../../services/api";
import { useLoaderContext } from "../../context/LoaderContext";
import Loader from "../Loader";

const EmployeeDashboard = () => {
  const navigate = useNavigate();
  const { isLoading, setLoading } = useLoaderContext();

  const [leaveCount, setLeaveCount] = useState([]);
  const [holidayList, setHolidayList] = useState([]);

  const [errMsg, setErrMsg] = useState("");
  const [errUnAuthorizedMsg, setErrUnAuthorizedMsg] = useState("");

  useEffect(() => {
    document.title = "DashBoard";
  }, []);
  useEffect(() => {
    if (errUnAuthorizedMsg !== "") {
      setLoading(false);
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: errUnAuthorizedMsg,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Go Back",
        cancelButtonText: "Login",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then(function (result) {
        setErrUnAuthorizedMsg("");
        setLoading(false);
        if (result.isConfirmed) {
          window.location.reload();
        } else {
          navigate("/login", { replace: true });
        }
      });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [errUnAuthorizedMsg]);
  useEffect(() => {
    if (errMsg !== "") {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: errMsg,
      }).then(function () {
        setErrMsg("");
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [errMsg]);

  useEffect(() => {
    async function fetchAPIDashboardInfo() {
      try {
        setLoading(true);
        let response = await Get("employee/getDashboardInfo");
        if (response.status === 401) {
          setLoading(false);
          setErrUnAuthorizedMsg(response.data);
        } else if (response.status === 404) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Api Not Found!",
          }).then(function () {
            navigate("/employee", { replace: true });
          });
        } else if (response.hasOwnProperty("error")) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: response.data,
          }).then(function () {
            window.location.reload();
          });
        } else {
          setLeaveCount(response.data.leaveCount);
          setHolidayList(response.data.holidayList);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.message,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: "Go Back",
          cancelButtonText: "Reload",
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then(function (result) {
          setLoading(false);
          if (result.isConfirmed) {
            navigate(-1);
          } else {
            window.location.reload();
          }
        });
      }
    }
    fetchAPIDashboardInfo();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {isLoading && <Loader />}
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2 justify-content-between">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">Dashboard</h1>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-8">
                <div className="card card-default">
                  <div className="card-body">
                    <div className="row">
                      {leaveCount &&
                        leaveCount.map((items, index) => (
                          <div className="col-sm-4" key={index}>
                            <div className="info-box bg-light">
                              <div className="info-box-content">
                                <span className="info-box-number text-muted">
                                  {items.leave_type}
                                </span>
                                <span className="info-box-text text-muted">
                                  Taken :{" "}
                                  {items.pending_leave + items.taken_leave}
                                </span>
                                <span className="info-box-text text-muted">
                                  Balance :{" "}
                                  {items.total_leave -
                                    items.pending_leave -
                                    items.taken_leave}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card card-default">
                  <div className="card-header">
                    <h3 className="card-title">Events</h3>
                  </div>
                  <div
                    className="card-body"
                    style={{ maxHeight: "500px", overflowY: "auto" }}
                  >
                    {holidayList &&
                      holidayList.map((items, index) => (
                        <div key={index}>
                          {items.type === "holiday" && (
                            <Link
                              key={index}
                              to={"/employee/viewHoliday/" + items.id}
                            >
                              <div className="alert alert-info">
                                <h5>{items.name}</h5>
                                {moment(items.date).format("Do MMMM")}
                              </div>
                            </Link>
                          )}
                          {items.type === "birthday" && (
                            <Link
                              key={index}
                              to={"/employee/viewEmployee/" + items.id}
                            >
                              <div className="alert alert-success">
                                <h5>{items.name} Birthday</h5>
                                {moment(items.date).format("Do MMMM")}
                              </div>
                            </Link>
                          )}

                          {items.type === "anniversary" && (
                            <Link
                              key={index}
                              to={"/employee/viewEmployee/" + items.id}
                            >
                              <div className="alert alert-warning">
                                <h5>{items.name} Anniversary</h5>
                                {moment(items.date).format("Do MMMM")}
                              </div>
                            </Link>
                          )}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default EmployeeDashboard;
