import { useNavigate, Link } from "react-router-dom";
import React from "react";

const Unauthorized = () => {
  const navigate = useNavigate();
  return (
    <article style={{ padding: "100px" }}>
      <h1>Unauthorized</h1>
      <br />
      <p>You do not have access to the requested page.</p>
      <div className="flexGrow">
        <Link to={navigate(-1)}>Go Back</Link>
      </div>
    </article>
  );
};

export default Unauthorized;
