import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Swal from "sweetalert2";
import { Post } from "../../services/api";
import { useLoaderContext } from "../../context/LoaderContext";
import Loader from "../Loader";

const EmployeeChangePassword = () => {
  const navigate = useNavigate();
  const { isLoading, setLoading } = useLoaderContext();

  const [password, setPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [cpassword, setCPassword] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [errUnAuthorizedMsg, setErrUnAuthorizedMsg] = useState("");

  useEffect(() => {
    document.title = "Change Password";
  }, []);
  useEffect(() => {
    setErrMsg("");
  }, [cpassword, oldPassword, password]);

  useEffect(() => {
    if (errUnAuthorizedMsg !== "") {
      setLoading(false);
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: errUnAuthorizedMsg,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Go Back",
        cancelButtonText: "Login",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then(function (result) {
        setErrUnAuthorizedMsg("");
        setLoading(false);
        if (result.isConfirmed) {
          navigate(-1);
        } else {
          navigate("/login", { replace: true });
        }
      });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [errUnAuthorizedMsg]);
  useEffect(() => {
    if (errMsg !== "") {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: errMsg,
      }).then(function () {
        setErrMsg("");
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [errMsg]);
  const handleSubmit = async () => {
    try {
      setLoading(true);
      setErrMsg("");

      if (password === "") {
        setErrMsg("New Password is require.");
        return;
      }
      if (cpassword === "") {
        setErrMsg("Confirm Password is require.");
        return;
      }
      if (oldPassword === "") {
        setErrMsg("Old Password is require.");
        return;
      }
      if (password === oldPassword) {
        setErrMsg("Old Password and new Password Not be same.");
        return;
      }
      if (password !== cpassword) {
        setErrMsg("new Password and Confirm Password Must be same.");
        return;
      }
      let payload = {
        password: password,
        cpassword: cpassword,
        oldPassword: oldPassword,
      };
      const response = await Post("employee/changePassword", payload);
      if (response.status === 401) {
        setLoading(false);
        setErrUnAuthorizedMsg(response.data);
      } else if (response.status === 404) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Api Not Found!",
        }).then(function () {
          navigate("/employee", { replace: true });
        });
      } else if (response.hasOwnProperty("error")) {
        setLoading(false);
        setErrMsg(response.data);
      } else {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data,
        }).then(function () {
          navigate("/employee", { replace: true });
        });
      }
    } catch (error) {
      setLoading(false);
      setErrMsg(error.message);
    }
  };
  return (
    <>
      {isLoading && <Loader />}
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">Change Password</h1>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="card card-primary">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row justify-content-center">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="oldpassword">Old Password</label>
                          <input
                            type="password"
                            id="oldpassword"
                            onChange={(e) => setOldPassword(e.target.value)}
                            value={oldPassword}
                            placeholder="Old Password"
                            required
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="password">New Password</label>
                          <input
                            type="password"
                            id="password"
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                            placeholder="Password"
                            required
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="cpassword">Confirm Password</label>
                          <input
                            type="password"
                            id="cpassword"
                            onChange={(e) => setCPassword(e.target.value)}
                            value={cpassword}
                            placeholder="Confirm Password"
                            required
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-md-3 m-2">
                        <button
                          className="btn btn-success btn-block"
                          onClick={handleSubmit}
                        >
                          Change Password
                        </button>
                      </div>
                      <div className="col-md-3 m-2">
                        <Link
                          to={"/employee"}
                          className="btn btn-danger btn-block"
                        >
                          Cancel
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default EmployeeChangePassword;
