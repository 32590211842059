import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

import Swal from "sweetalert2";
import { Get, GetProfileImageById } from "../../../services/api";
import { useLoaderContext } from "../../../context/LoaderContext";
import Loader from "../../Loader";

const EmployeeList = () => {
  const { SearchBar } = Search;
  const navigate = useNavigate();
  const { isLoading, setLoading } = useLoaderContext();
  const [imageUrls, setImageUrls] = useState({});
  const [currenPage, setCurrentPage] = useState(1);
  const [currenLimit, setCurrentLimit] = useState(10);
  const [currenData, setCurrentData] = useState([]);
  const [errUnAuthorizedMsg, setErrUnAuthorizedMsg] = useState("");

  useEffect(() => {
    document.title = "Employee List";
  }, []);
  useEffect(() => {
    if (errUnAuthorizedMsg !== "") {
      setLoading(false);
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: errUnAuthorizedMsg,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Go Back",
        cancelButtonText: "Login",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then(function (result) {
        setErrUnAuthorizedMsg("");
        setLoading(false);
        if (result.isConfirmed) {
          navigate(-1);
        } else {
          navigate("/login", { replace: true });
        }
      });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [errUnAuthorizedMsg]);
  const columns = [
    {
      dataField: "employee_unique_id",
      text: "Employee Id",
      sort: true,
    },
    {
      dataField: "Profile",
      text: "Profile",
      formatter: (cellContent, row) => (
        <img
          src={imageUrls[row.id]}
          alt={`Profile`}
          style={{ width: "50px", height: "50px" }}
        />
      ),
    },
    {
      dataField: "first_name",
      text: "First Name",
      sort: true,
    },
    {
      dataField: "last_name",
      text: "Last Name",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
    },
    {
      dataField: "gender",
      text: "Gender",
    },
    {
      dataField: "department",
      text: "Department",
    },
    {
      dataField: "status",
      text: "Status",
    },
    {
      dataField: "Action",
      text: "Action",
      classes: "text-center",
      formatter: (cellContent, row) => {
        return (
          <button className="btn btn-info" onClick={() => handleView(row)}>
            <i className="fas fa-eye"></i>
          </button>
        );
      },
    },
  ];
  const handelGetProfileImageById = async (id) => {
    let pImgT = await GetProfileImageById(id);
    if (!pImgT.hasOwnProperty("error")) {
      if (pImgT) {
        return `data:image/jpeg;base64,${pImgT}`;
      } else {
        return "";
      }
    } else {
      return "";
    }
  };
  const handleView = (row) => {
    navigate("/employee/viewEmployee/" + row.id, { replace: true });
  };

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];
  const pagination = paginationFactory({
    page: currenPage,
    sizePerPage: currenLimit,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      setCurrentPage(sizePerPage);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      setCurrentLimit(page);
    },
  });
  useEffect(() => {
    async function fetchAPIEmployeeList() {
      try {
        setLoading(true);
        const response = await Get("employee/getEmployeeList");
        if (response.status === 401) {
          setLoading(false);
          setErrUnAuthorizedMsg(response.data);
        } else if (response.status === 404) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Api Not Found!",
          }).then(function () {
            navigate("/employee", { replace: true });
          });
        } else if (response.hasOwnProperty("error")) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: response.data,
          }).then(function () {
            window.location.reload();
          });
        } else {
          setLoading(false);
          let urls = {};
          const promises = response.data.map(async (user) => {
            const imageUrl = await handelGetProfileImageById(user.id);
            urls[user.id] = imageUrl;
          });
          await Promise.all(promises);
          setImageUrls(urls);
          setCurrentData(response.data);
        }
      } catch (error) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.message,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: "Go Back",
          cancelButtonText: "Reload",
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then(function (result) {
          setLoading(false);
          if (result.isConfirmed) {
            navigate("/employee");
          } else {
            window.location.reload();
          }
        });
      }
    }
    fetchAPIEmployeeList();
    // eslint-disable-next-line
  }, []);
  const NoDataIndicator = () => (
    <div className="no-data-indicator">No records found</div>
  );
  return (
    <>
      {isLoading && <Loader />}
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">Employee List</h1>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <ToolkitProvider
                      bootstrap4
                      keyField="id"
                      data={currenData}
                      columns={columns}
                      search
                    >
                      {(props) => (
                        <div className="row">
                          <div className="col-sm-4">
                            <SearchBar {...props.searchProps} />
                          </div>
                          <div className="">
                            <BootstrapTable
                              {...props.baseProps}
                              pagination={pagination}
                              defaultSorted={defaultSorted}
                              noDataIndication={<NoDataIndicator />}
                              striped
                            />
                          </div>
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default EmployeeList;
