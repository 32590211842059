import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import Swal from "sweetalert2";
import moment from "moment/moment";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import { Post } from "../../../services/api";
import { useLoaderContext } from "../../../context/LoaderContext";
import Loader from "../../Loader";

const LeaveDetails = () => {
  const navigate = useNavigate();
  const { isLoading, setLoading } = useLoaderContext();

  const [email, setEmail] = useState("");
  const [empId, setEmpId] = useState("");
  const [name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [leaveType, setLeaveType] = useState("");
  const [leavePostingDate, setLeavePostingDate] = useState("");
  const [leaveFrom, setLeaveFrom] = useState("");
  const [leaveTo, setLeaveTo] = useState("");
  const [leaveDescription, setLeaveDescription] = useState("");
  const [leaveStatus, setLeaveStatus] = useState("");
  const [leaveStatusColor, setLeaveStatusColor] = useState("");
  const [approversRemark, setApproversRemark] = useState("");
  const [approvalDate, setApprovalDate] = useState("");
  const [isHalf, setIsHalf] = useState("");
  const [whichHalf, setWhichHalf] = useState("");
  const [takeActionModalShow, setTakeActionModalShow] = useState(false);
  const [takeAction, setTakeAction] = useState({});
  const [takeActionDescription, setTakeActionDescription] = useState("");
  const takeActionOptions = [
    { value: "Approved", label: "Approved" },
    { value: "Not Approved", label: "Not Approved" },
  ];

  const params = useParams();

  const [errMsg, setErrMsg] = useState("");
  const [errUnAuthorizedMsg, setErrUnAuthorizedMsg] = useState("");

  useEffect(() => {
    document.title = "Leave Detail";
  }, []);
  useEffect(() => {
    setErrMsg("");
  }, [takeAction, takeActionDescription]);

  useEffect(() => {
    if (errMsg !== "") {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: errMsg,
      }).then(function () {
        setErrMsg("");
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [errMsg]);

  useEffect(() => {
    if (errUnAuthorizedMsg !== "") {
      setLoading(false);
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: errUnAuthorizedMsg,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Go Back",
        cancelButtonText: "Login",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then(function (result) {
        setErrUnAuthorizedMsg("");
        setLoading(false);
        if (result.isConfirmed) {
          navigate(-1);
        } else {
          navigate("/admin/login", { replace: true });
        }
      });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [errUnAuthorizedMsg]);

  useEffect(() => {
    async function fetchAPIGetLeaveDetail() {
      try {
        setLoading(true);
        let payload = { id: params.id };
        let response = await Post("admin/getLeaveDetail", payload);
        if (response.status === 401) {
          setLoading(false);
          setErrUnAuthorizedMsg(response.data);
        } else if (response.status === 404) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Api Not Found!",
          }).then(function () {
            navigate("/admin", { replace: true });
          });
        } else if (response.hasOwnProperty("error")) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: response.data,
          }).then(function () {
            window.location.reload();
          });
        } else {
          setEmail(response.data[0].email);
          setName(
            response.data[0].first_name + " " + response.data[0].last_name
          );
          setEmpId(response.data[0].employee_unique_id);
          setGender(response.data[0].gender);
          setContactNo(response.data[0].mobile_no);
          setLeaveType(response.data[0].leave_type);
          setLeavePostingDate(response.data[0].created_at);
          setLeaveFrom(response.data[0].from_date);
          setLeaveTo(response.data[0].to_date);
          setLeaveDescription(
            response.data[0].description
              ? response.data[0].description.replace(/----/g, "'")
              : ""
          );
          setLeaveStatus(response.data[0].status);
          setApproversRemark(
            response.data[0].approvers_remark
              ? response.data[0].approvers_remark.replace(/----/g, "'")
              : ""
          );
          setApprovalDate(response.data[0].approval_date);
          setWhichHalf(response.data[0].which_half);
          setIsHalf(response.data[0].is_half_leave);

          if (response.data[0].status === "Approved") {
            setLeaveStatusColor("greenColor");
          } else if (response.data[0].status === "Not Approved") {
            setLeaveStatusColor("redColor");
          } else {
            setLeaveStatusColor("blueColor");
          }

          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.message,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: "Go Back",
          cancelButtonText: "Reload",
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then(function (result) {
          setLoading(false);
          if (result.isConfirmed) {
            navigate("/admin/allLeaves");
          } else {
            window.location.reload();
          }
        });
      }
    }
    fetchAPIGetLeaveDetail();

    // eslint-disable-next-line
  }, [params]);

  const handleOpen = () => {
    setTakeActionModalShow(true);
  };
  const handleClose = () => {
    setTakeActionModalShow(false);
    setTakeAction({});
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      setErrMsg("");
      if (takeAction.value === "" || takeAction.value === undefined) {
        setErrMsg("Select Approved Or Not Approved.");
        return;
      }
      let payload = {
        id: params.id,
        takeActionDescription: takeActionDescription,
        takeAction: takeAction.value,
      };
      const response = await Post("admin/takeActionLeave", payload);
      if (response.status === 401) {
        setLoading(false);
        setErrUnAuthorizedMsg(response.data);
      } else if (response.status === 404) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Api Not Found!",
        }).then(function () {
          navigate("/admin", { replace: true });
        });
      } else if (response.hasOwnProperty("error")) {
        setLoading(false);
        setErrMsg(response.data);
      } else {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data,
        }).then(function () {
          navigate("/admin/allLeaves", { replace: true });
        });
      }
    } catch (error) {
      setLoading(false);
      setErrMsg(error.message);
    }
  };
  return (
    <>
      {isLoading && <Loader />}
      <div className="content-wrapper">
        <Modal show={takeActionModalShow} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Leave Take Action</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <Select
                id="takeAction"
                options={takeActionOptions}
                value={takeAction}
                onChange={setTakeAction}
              />
            </div>
            <div className="form-group">
              <textarea
                type="text"
                id="takeActionDescription"
                onChange={(e) => setTakeActionDescription(e.target.value)}
                value={takeActionDescription}
                placeholder="Description"
                className="form-control"
                rows="3"
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-success" onClick={handleSubmit}>
              Submit
            </button>
            <button className="btn btn-danger" onClick={handleClose}>
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">Leave Detail</h1>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-2">
                  <h6>
                    <b>Emp Name :</b>
                  </h6>
                </div>
                <div className="col-md-4">{name}</div>
                <div className="col-md-1">
                  <h6>
                    <b>Emp Id :</b>
                  </h6>
                </div>
                <div className="col-md-3">{empId}</div>
                <div className="col-md-1">
                  <h6>
                    <b>Gender :</b>
                  </h6>
                </div>
                <div className="col-md-1">{gender}</div>
              </div>
              <hr />
              <div className="row">
                <div className="col-md-2">
                  <h6>
                    <b>Emp Email :</b>
                  </h6>
                </div>
                <div className="col-md-4">{email}</div>
                <div className="col-md-2">
                  <h6>
                    <b>Emp Contact :</b>
                  </h6>
                </div>
                <div className="col-md-4">{contactNo}</div>
              </div>
              <hr />
              <div className="row">
                <div className="col-md-2">
                  <h6>
                    <b>Leave Type :</b>
                  </h6>
                </div>
                <div className="col-md-4">{leaveType}</div>
                <div className="col-md-2">
                  <h6>
                    <b>Apply Date :</b>
                  </h6>
                </div>
                <div className="col-md-4">
                  {moment(leavePostingDate).format("YYYY-MM-DD h:mm:ss A")}
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-md-2">
                  <h6>
                    <b>Leave From :</b>
                  </h6>
                </div>
                <div className="col-md-4">
                  {moment(leaveFrom).format("YYYY-MM-DD")}
                </div>
                <div className="col-md-2">
                  <h6>
                    <b>Leave To :</b>
                  </h6>
                </div>
                <div className="col-md-4">
                  {moment(leaveTo).format("YYYY-MM-DD")}
                </div>
              </div>
              <hr />
              {isHalf === "1" && (
                <>
                  <div className="row">
                    <div className="col-md-4">
                      Applied For {whichHalf} Half.
                    </div>
                  </div>
                  <hr />
                </>
              )}
              <div className="row">
                <div className="col-md-2">
                  <h6>
                    <b>Leave Description :</b>
                  </h6>
                </div>
                <div className="col-md-6">{leaveDescription}</div>
              </div>
              <hr />
              <div className="row">
                <div className="col-md-2">
                  <h6>
                    <b>Leave Status :</b>
                  </h6>
                </div>
                <div className={`col-md-6 ${leaveStatusColor}`}>
                  {leaveStatus}
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-md-2">
                  <h6>
                    <b>Approvers Remark :</b>
                  </h6>
                </div>
                <div className="col-md-6">{approversRemark}</div>
              </div>
              <hr />
              <div className="row">
                <div className="col-md-2">
                  <h6>
                    <b>Approval Date :</b>
                  </h6>
                </div>
                <div className="col-md-6">
                  {approvalDate === null
                    ? "N/A"
                    : moment(approvalDate).format("YYYY-MM-DD h:mm:ss A")}
                </div>
              </div>
              <hr />
              <div className="row justify-content-center">
                {leaveStatus === "Waiting for Approval" && (
                  <div className="col-md-3 m-2">
                    <button
                      className="btn btn-success btn-block"
                      onClick={handleOpen}
                    >
                      Take Action
                    </button>
                  </div>
                )}
                <div className="col-md-3 m-2">
                  <Link
                    to={"/admin/allLeaves"}
                    className="btn btn-danger btn-block"
                  >
                    Back
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default LeaveDetails;
