import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import Swal from "sweetalert2";
import Select from "react-select";
import moment from "moment/moment";
import { DateRange } from "react-date-range";
import DatePicker from "react-date-picker";

import { Post, Get } from "../../../services/api";
import { useLoaderContext } from "../../../context/LoaderContext";
import Loader from "../../Loader";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

const UpdateLeave = () => {
  const navigate = useNavigate();
  const { isLoading, setLoading } = useLoaderContext();

  const [description, setDescription] = useState("");
  const [id, setId] = useState("");
  const [oldLeaveType, setOldLeaveType] = useState(0);
  const [oldDayCount, setOldDayCount] = useState(0.0);
  const [leaveTypeList, setLeaveTypeList] = useState([]);
  const [currentLeaveType, setCurrentLeaveType] = useState({});
  const [holidayList, setHolidayList] = useState([]);
  const [singleDate, setSingleDate] = useState(new Date());
  const [leaveType, setLeaveType] = useState({});
  const leaveTypeOptions = [
    { value: "half", label: "Half" },
    { value: "full", label: "Full" },
  ];
  const [whichHalf, setWhichHalf] = useState({});
  const whichHalfOptions = [
    { value: "First", label: "First" },
    { value: "Second", label: "Second" },
  ];
  const [selectedDateRange, setSelectedDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const params = useParams();

  const [errMsg, setErrMsg] = useState("");
  const [errUnAuthorizedMsg, setErrUnAuthorizedMsg] = useState("");

  useEffect(() => {
    document.title = "Update Leave";
  }, []);
  useEffect(() => {
    setErrMsg("");
  }, [
    currentLeaveType,
    description,
    selectedDateRange,
    whichHalf,
    singleDate,
    leaveType,
  ]);

  useEffect(() => {
    if (errMsg !== "") {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: errMsg,
      }).then(function () {
        setErrMsg("");
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [errMsg]);

  useEffect(() => {
    if (errUnAuthorizedMsg !== "") {
      setLoading(false);
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: errUnAuthorizedMsg,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Go Back",
        cancelButtonText: "Login",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then(function (result) {
        setErrUnAuthorizedMsg("");
        setLoading(false);
        if (result.isConfirmed) {
          navigate(-1);
        } else {
          navigate("/login", { replace: true });
        }
      });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [errUnAuthorizedMsg]);

  useEffect(() => {
    async function fetchAPIHolidays() {
      try {
        setLoading(true);
        const response = await Get("employee/getHolidayList");
        if (response.status === 401) {
          setLoading(false);
          setErrUnAuthorizedMsg(response.data);
        } else if (response.status === 404) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Api Not Found!",
          }).then(function () {
            navigate("/employee", { replace: true });
          });
        } else if (response.hasOwnProperty("error")) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: response.data,
          }).then(function () {
            window.location.reload();
          });
        } else {
          setLoading(false);
          // eslint-disable-next-line
          response.data.map((items) => {
            setHolidayList((holidayList) => [
              ...holidayList,
              moment(items.date).format("YYYY-MM-DD"),
            ]);
          });
        }
      } catch (error) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.message,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: "Go Back",
          cancelButtonText: "Reload",
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then(function (result) {
          setLoading(false);
          if (result.isConfirmed) {
            navigate("/employee/leaveList");
          } else {
            window.location.reload();
          }
        });
      }
    }
    fetchAPIHolidays();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    async function fetchAPIBalance() {
      try {
        setLoading(true);
        const response = await Get("employee/getBalanceLeave");
        if (response.status === 401) {
          setLoading(false);
          setErrUnAuthorizedMsg(response.data);
        } else if (response.status === 404) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Api Not Found!",
          }).then(function () {
            navigate("/employee", { replace: true });
          });
        } else if (response.hasOwnProperty("error")) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: response.data,
          }).then(function () {
            window.location.reload();
          });
        } else {
          setLoading(false);
          // eslint-disable-next-line
          response.data.map((items) => {
            setLeaveTypeList((leaveTypeList) => [
              ...leaveTypeList,
              {
                label: items.leave_type,
                value: items.leave_type_id,
                remainingLeave:
                  items.total_leave - items.pending_leave - items.taken_leave,
              },
            ]);
          });
        }
      } catch (error) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.message,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: "Go Back",
          cancelButtonText: "Reload",
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then(function (result) {
          setLoading(false);
          if (result.isConfirmed) {
            navigate("/employee/leaveList");
          } else {
            window.location.reload();
          }
        });
      }
    }
    fetchAPIBalance();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    async function fetchAPISingleLeave() {
      try {
        setLoading(true);
        let payload = { id: params.id };
        const response = await Post("employee/getSingleLeave", payload);
        if (response.status === 401) {
          setLoading(false);
          setErrUnAuthorizedMsg(response.data);
        } else if (response.status === 404) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Api Not Found!",
          }).then(function () {
            navigate("/employee", { replace: true });
          });
        } else if (response.hasOwnProperty("error")) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: response.data,
          }).then(function () {
            window.location.reload();
          });
        } else {
          setLoading(false);
          // eslint-disable-next-line
          if (response.data[0].is_half_leave === "1") {
            setSingleDate(new Date(response.data[0].from_date));
            setLeaveType({ value: "half", label: "Half" });
            setWhichHalf({
              value: response.data[0].which_half,
              label: response.data[0].which_half,
            });
          } else {
            setLeaveType({ value: "full", label: "Full" });
            setSelectedDateRange([
              {
                startDate: new Date(response.data[0].from_date),
                endDate: new Date(response.data[0].to_date),
                key: "selection",
              },
            ]);
          }
          setId(response.data[0].id);
          setDescription(
            response.data[0].description
              ? response.data[0].description.replace(/----/g, "'")
              : ""
          );
          setOldDayCount(response.data[0].dayCount);
          setOldLeaveType(response.data[0].leave_type);
        }
      } catch (error) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.message,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: "Go Back",
          cancelButtonText: "Reload",
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then(function (result) {
          setLoading(false);
          if (result.isConfirmed) {
            navigate("/employee/leaveList");
          } else {
            window.location.reload();
          }
        });
      }
    }
    fetchAPISingleLeave();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (leaveTypeList.length > 0 && oldLeaveType > 0) {
      setLeaveTypeList((leaveTypeList) =>
        leaveTypeList.map((items) => {
          if (items.value === oldLeaveType) {
            setCurrentLeaveType({
              label: items.label,
              value: items.value,
              remainingLeave: items.remainingLeave + oldDayCount,
            });
            return {
              label: items.label,
              value: items.value,
              remainingLeave: items.remainingLeave + oldDayCount,
            };
          } else {
            return {
              label: items.label,
              value: items.value,
              remainingLeave: items.remainingLeave,
            };
          }
        })
      );
    }
    // eslint-disable-next-line
  }, [oldLeaveType]);

  const handleSelect = (ranges) => {
    setSelectedDateRange([ranges.selection]);
  };

  const isHoliday = (date) => {
    return holidayList.includes(moment(date).format("YYYY-MM-DD"));
  };

  const isWeekend = (date) => {
    const day = date.getDay();
    return day === 0 || day === 6;
  };
  const disableDates = (date) => {
    return isHoliday(date) || isWeekend(date);
  };
  const calculateDateCount = (startDate, endDate) => {
    let count = 0;
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      if (!isHoliday(currentDate) && !isWeekend(currentDate)) {
        count++;
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return count;
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      setErrMsg("");

      if (
        currentLeaveType.value === "" ||
        currentLeaveType.value === undefined
      ) {
        setErrMsg("Leave Type is require.");
        return;
      }
      if (leaveType.value === "" || leaveType.value === undefined) {
        setErrMsg("Select Leave First.");
        return;
      }
      let dateCount = 0;
      let selectedStartDate = "";
      let selectedEndDate = "";
      let halfWhich = "";
      if (leaveType.value === "half") {
        selectedStartDate = singleDate;
        selectedEndDate = singleDate;
        dateCount = 0.5;
        halfWhich = whichHalf.value;
        if (halfWhich === "" || halfWhich === undefined) {
          setErrMsg("Select Which Half.");
          return;
        }
      } else if (leaveType.value === "full") {
        selectedStartDate = selectedDateRange[0].startDate;
        selectedEndDate = selectedDateRange[0].endDate;
        dateCount = calculateDateCount(selectedStartDate, selectedEndDate);
        halfWhich = "";
      }
      if (dateCount > currentLeaveType.remainingLeave) {
        setErrMsg("Select Less date than Balance.");
        return;
      }
      if (dateCount === 0) {
        setErrMsg("Please Select Proper Dates.");
        return;
      }
      if (selectedStartDate === "" || selectedEndDate === "") {
        setErrMsg("Please Select Proper Dates.");
        return;
      }
      if (description === "") {
        setErrMsg("Add Proper Description.");
        return;
      }
      let payload = {
        id: id,
        leaveTypeId: currentLeaveType.value,
        leaveType: leaveType.value,
        dateCount: dateCount,
        description: description,
        whichHalf: halfWhich,
        from: moment(selectedStartDate).format("YYYY-MM-DD"),
        to: moment(selectedEndDate).format("YYYY-MM-DD"),
      };
      const response = await Post("employee/updateLeave", payload);
      if (response.status === 401) {
        setLoading(false);
        setErrUnAuthorizedMsg(response.data);
      } else if (response.status === 404) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Api Not Found!",
        }).then(function () {
          navigate("/employee", { replace: true });
        });
      } else if (response.hasOwnProperty("error")) {
        setLoading(false);
        setErrMsg(response.data);
      } else {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data,
        }).then(function () {
          navigate("/employee/leaveList", { replace: true });
        });
      }
    } catch (error) {
      setLoading(false);
      setErrMsg(error.message);
    }
  };
  return (
    <>
      {isLoading && <Loader />}
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">Update Leave</h1>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="card card-primary">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="leaveType">Leave Type</label>
                          <Select
                            id="leaveType"
                            options={leaveTypeList}
                            value={currentLeaveType}
                            onChange={setCurrentLeaveType}
                          />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="info-box shadow-none">
                          <div className="info-box-content">
                            <span className="info-box-text">Balance</span>
                            <span className="info-box-number">
                              {currentLeaveType.remainingLeave}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="leave">Select Leave</label>
                          <Select
                            id="leave"
                            options={leaveTypeOptions}
                            value={leaveType}
                            onChange={setLeaveType}
                          />
                        </div>
                      </div>
                      {leaveType.value === "half" && (
                        <>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label htmlFor="date">Date</label>
                              <DatePicker
                                id="date"
                                className="form-control"
                                value={singleDate}
                                onChange={setSingleDate}
                                format="yyyy-MM-dd"
                                clearIcon={null}
                                tileDisabled={({ date }) => disableDates(date)}
                              />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label htmlFor="whichHalf">Which Half</label>
                              <Select
                                id="whichHalf"
                                options={whichHalfOptions}
                                value={whichHalf}
                                onChange={setWhichHalf}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    {leaveType.value === "full" && (
                      <div className="row justify-content-between">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="selectDate">Select Date</label>
                            <DateRange
                              ranges={selectedDateRange}
                              onChange={handleSelect}
                              disabledDay={disableDates}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="description">Description</label>
                          <textarea
                            type="text"
                            id="description"
                            onChange={(e) => setDescription(e.target.value)}
                            value={description}
                            placeholder="Description"
                            className="form-control"
                            rows="3"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-md-3 m-2">
                        <button
                          className="btn btn-success btn-block"
                          onClick={handleSubmit}
                        >
                          Update Leave
                        </button>
                      </div>
                      <div className="col-md-3 m-2">
                        <Link
                          to={"/employee/leaveList"}
                          className="btn btn-danger btn-block"
                        >
                          Cancel
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default UpdateLeave;
