import React from "react";
import { Routes, Route } from "react-router-dom";

import "./App.css";
import { LoaderProvider } from "./context/LoaderContext";

//common component
import Unauthorized from "./components/Unauthorized";
import Missing from "./components/Missing";
import RequireAuth from "./components/RequireAuth";

//employee component
import Login from "./components/auth/Login";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";

import Employee from "./components/employee/Employee";
import EmployeeDashboard from "./components/employee/EmployeeDashboard";
import EmployeeChangePassword from "./components/employee/EmployeeChangePassword";
import Profile from "./components/employee/Profile";
import ProfileView from "./components/employee/ProfileView";

import HolidayList from "./components/employee/Holiday/holidayList";
import ViewHoliday from "./components/employee/Holiday/viewHoliday";

import EmployeeList from "./components/employee/Employee/employeeList";
import ViewEmployee from "./components/employee/Employee/viewEmployee";

import TimeSheetList from "./components/employee/TimeSheet/timeSheetList";
import SalarySlip from "./components/employee/SalarySlip/SalarySlip";

import AddLeave from "./components/employee/Leaves/addLeave";
import LeaveList from "./components/employee/Leaves/leaveList";
import ViewLeave from "./components/employee/Leaves/viewLeave";
import UpdateLeave from "./components/employee/Leaves/updateLeave";

//admin component
import AdminLogin from "./components/auth/admin/Login";
import AdminForgotPassword from "./components/AdminForgotPassword";
import AdminResetPassword from "./components/AdminResetPassword";

import Admin from "./components/admin/Admin";
import AdminDashboard from "./components/admin/AdminDashboard";
import AdminChangePassword from "./components/admin/AdminChangePassword";
import AdminProfile from "./components/admin/Profile";
import AdminProfileView from "./components/admin/ProfileView";

import AdminTimeSheetList from "./components/admin/TimeSheet/timeSheetList";
import AdminSalarySlip from "./components/admin/SalarySlip/SalarySlip";

import AdminDepartmentList from "./components/admin/Department/departmentList";
import AdminAddDepartment from "./components/admin/Department/addDepartment";
import AdminUpdateDepartment from "./components/admin/Department/updateDepartment";
import AdminViewDepartment from "./components/admin/Department/viewDepartment";

import AdminLeaveTypeList from "./components/admin/LeaveType/leaveTypeList";
import AdminAddLeaveType from "./components/admin/LeaveType/addLeaveType";
import AdminUpdateLeaveType from "./components/admin/LeaveType/updateLeaveType";
import AdminViewLeaveType from "./components/admin/LeaveType/viewLeaveType";

import AdminEmployeeList from "./components/admin/Employee/employeeList";
import AdminAddEmployee from "./components/admin/Employee/addEmployee";
import AdminUpdateEmployee from "./components/admin/Employee/updateEmployee";
import AdminViewEmployee from "./components/admin/Employee/viewEmployee";

import AdminAdminList from "./components/admin/Admin/adminList";
import AdminAddAdmin from "./components/admin/Admin/addAdmin";
import AdminUpdateAdmin from "./components/admin/Admin/updateAdmin";
import AdminViewAdmin from "./components/admin/Admin/viewAdmin";

import AdminHolidayList from "./components/admin/Holiday/holidayList";
import AdminAddHoliday from "./components/admin/Holiday/addHoliday";
import AdminUpdateHoliday from "./components/admin/Holiday/updateHoliday";
import AdminViewHoliday from "./components/admin/Holiday/viewHoliday";

import AdminApplyLeave from "./components/admin/Leaves/applyLeave";
import AdminBalanceLeave from "./components/admin/Leaves/balanceLeave";
import AdminAllLeaveList from "./components/admin/Leaves/allLeave";
import AdminPendingLeaveList from "./components/admin/Leaves/pendingLeave";
import AdminApprovedLeaveList from "./components/admin/Leaves/approvedLeave";
import AdminNotApprovedLeaveList from "./components/admin/Leaves/notApprovedLeave";
import AdminLeaveDetails from "./components/admin/Leaves/leaveDetails";
import AdminUpdateLeaveDetails from "./components/admin/Leaves/updateLeaveDetails";

function App() {
  return (
    <LoaderProvider>
      <div className="wrapper">
        <Routes>
          {/* common route */}
          <Route path="/unauthorized" element={<Unauthorized />} />
          {/* employee Route */}
          <Route path="/" element={<Login />} />
          <Route path="/login" exact element={<Login />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route
            path="/resetPassword/:resetPasswordKey"
            element={<ResetPassword />}
          />
          <Route element={<RequireAuth userType={"employee"} />}>
            <Route element={<Employee />}>
              <Route path="/employee" element={<EmployeeDashboard />} />
              <Route
                path="/employee/changePassword"
                element={<EmployeeChangePassword />}
              />
              <Route path="/employee/profile" element={<Profile />} />
              <Route path="/employee/profileView" element={<ProfileView />} />

              <Route
                path="/employee/timeSheetList"
                element={<TimeSheetList />}
              />

              <Route path="/employee/salarySlip" element={<SalarySlip />} />

              <Route path="/employee/holidayList" element={<HolidayList />} />
              <Route
                path="/employee/viewHoliday/:id"
                element={<ViewHoliday />}
              />

              <Route path="/employee/employeeList" element={<EmployeeList />} />
              <Route
                path="/employee/viewEmployee/:id"
                element={<ViewEmployee />}
              />

              <Route path="/employee/addLeave" element={<AddLeave />} />
              <Route path="/employee/leaveList" element={<LeaveList />} />
              <Route path="/employee/viewLeave/:id" element={<ViewLeave />} />
              <Route
                path="/employee/updateLeave/:id"
                element={<UpdateLeave />}
              />
            </Route>
          </Route>

          <Route path="/admin/login" exact element={<AdminLogin />} />
          <Route
            path="/admin/forgotPassword"
            element={<AdminForgotPassword />}
          />
          <Route
            path="/admin/resetPassword/:resetPasswordKey"
            element={<AdminResetPassword />}
          />
          <Route element={<RequireAuth userType={"admin"} />}>
            <Route element={<Admin />}>
              <Route path="/admin" element={<AdminDashboard />} />
              <Route
                path="/admin/changePassword"
                element={<AdminChangePassword />}
              />
              <Route path="/admin/profile" element={<AdminProfile />} />
              <Route path="/admin/profileView" element={<AdminProfileView />} />
              <Route
                path="/admin/timeSheetList"
                element={<AdminTimeSheetList />}
              />
              <Route path="/admin/salarySlip" element={<AdminSalarySlip />} />
              <Route
                path="/admin/departmentList"
                element={<AdminDepartmentList />}
              />
              <Route
                path="/admin/addDepartment"
                element={<AdminAddDepartment />}
              />
              <Route
                path="/admin/updateDepartment/:id"
                element={<AdminUpdateDepartment />}
              />
              <Route
                path="/admin/viewDepartment/:id"
                element={<AdminViewDepartment />}
              />

              <Route
                path="/admin/leaveTypeList"
                element={<AdminLeaveTypeList />}
              />
              <Route
                path="/admin/addLeaveType"
                element={<AdminAddLeaveType />}
              />
              <Route
                path="/admin/updateLeaveType/:id"
                element={<AdminUpdateLeaveType />}
              />
              <Route
                path="/admin/viewLeaveType/:id"
                element={<AdminViewLeaveType />}
              />

              <Route
                path="/admin/employeeList"
                element={<AdminEmployeeList />}
              />
              <Route path="/admin/addEmployee" element={<AdminAddEmployee />} />
              <Route
                path="/admin/updateEmployee/:id"
                element={<AdminUpdateEmployee />}
              />
              <Route
                path="/admin/viewEmployee/:id"
                element={<AdminViewEmployee />}
              />

              <Route path="/admin/adminList" element={<AdminAdminList />} />
              <Route path="/admin/addAdmin" element={<AdminAddAdmin />} />
              <Route
                path="/admin/updateAdmin/:id"
                element={<AdminUpdateAdmin />}
              />
              <Route path="/admin/viewAdmin/:id" element={<AdminViewAdmin />} />

              <Route path="/admin/holidayList" element={<AdminHolidayList />} />
              <Route path="/admin/addHoliday" element={<AdminAddHoliday />} />
              <Route
                path="/admin/updateHoliday/:id"
                element={<AdminUpdateHoliday />}
              />
              <Route
                path="/admin/viewHoliday/:id"
                element={<AdminViewHoliday />}
              />

              <Route path="/admin/applyLeave" element={<AdminApplyLeave />} />
              <Route
                path="/admin/balanceLeave"
                element={<AdminBalanceLeave />}
              />
              <Route path="/admin/allLeaves" element={<AdminAllLeaveList />} />
              <Route
                path="/admin/pendingLeaves"
                element={<AdminPendingLeaveList />}
              />
              <Route
                path="/admin/approvedLeaves"
                element={<AdminApprovedLeaveList />}
              />
              <Route
                path="/admin/notApprovedleaves"
                element={<AdminNotApprovedLeaveList />}
              />
              <Route
                path="/admin/leaveDetails/:id"
                element={<AdminLeaveDetails />}
              />
              <Route
                path="/admin/updateLeaveDetails/:id"
                element={<AdminUpdateLeaveDetails />}
              />
            </Route>
          </Route>
          <Route path="*" element={<Missing />} />
        </Routes>
      </div>
    </LoaderProvider>
  );
}

export default App;
