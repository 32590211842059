import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import Swal from "sweetalert2";
import { Post } from "../services/api";
import { useLoaderContext } from "../context/LoaderContext";
import Loader from "./Loader";

const ResetPassword = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { isLoading, setLoading } = useLoaderContext();
  const [errUnAuthorizedMsg, setErrUnAuthorizedMsg] = useState("");
  const [pwd, setPwd] = useState("");
  const [cpwd, setCPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    document.title = "Reset Password";
  }, []);
  useEffect(() => {
    setErrMsg("");
  }, [pwd, cpwd]);
  useEffect(() => {
    if (errUnAuthorizedMsg !== "") {
      setLoading(false);
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: errUnAuthorizedMsg,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Go Back",
        cancelButtonText: "Login",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then(function (result) {
        setErrUnAuthorizedMsg("");
        setLoading(false);
        if (result.isConfirmed) {
          navigate(-1);
        } else {
          navigate("/admin/login", { replace: true });
        }
      });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [errUnAuthorizedMsg]);
  useEffect(() => {
    if (errMsg !== "") {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: errMsg,
      }).then(function () {
        setErrMsg("");
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [errMsg]);
  const handleSubmit = async () => {
    try {
      setLoading(true);
      if (pwd === "") {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Password is Required!",
        });
        setLoading(false);
        return;
      }
      if (cpwd === "") {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Confirm Password is Required!",
        });
        setLoading(false);
        return;
      }
      if (pwd !== cpwd) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Password and Confirm Password is Not Match!",
        });
        setLoading(false);
        return;
      }
      let payload = {
        password: pwd,
        cpassword: cpwd,
        resetPasswordKey: params.resetPasswordKey,
      };
      const response = await Post("admin/resetPassword", payload);

      if (response.status === 401) {
        setLoading(false);
        setErrUnAuthorizedMsg(response.data);
      } else if (response.status === 404) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Api Not Found!",
        }).then(function () {
          navigate("/admin/login", { replace: true });
        });
      } else if (response.hasOwnProperty("error")) {
        setLoading(false);
        setErrMsg(response.data);
      } else {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data,
        }).then(function () {
          setErrMsg("");
          setLoading(false);
          navigate("/admin/login", { replace: true });
          window.location.reload();
        });
      }
    } catch (error) {
      setLoading(false);
      setErrMsg(error.message);
    }
  };
  return (
    <>
      {isLoading && <Loader />}
      <div className="login-page iframe-mode">
        <div className="login-box">
          <div className="card card-outline card-primary">
            <div className="card-header text-center">
              <h1>
                <b>CoreFragment</b>
              </h1>
            </div>
            <div className="card-body">
              <p className="login-box-msg">
                You are only one step a way from your new password, recover your
                password now.
              </p>
              <div className="input-group mb-3">
                <input
                  type="password"
                  id="password"
                  autoComplete="off"
                  onChange={(e) => setPwd(e.target.value)}
                  value={pwd}
                  required
                  className="form-control"
                  placeholder="Password"
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock"></span>
                  </div>
                </div>
              </div>
              <div className="input-group mb-3">
                <input
                  type="password"
                  id="cpassword"
                  autoComplete="off"
                  onChange={(e) => setCPwd(e.target.value)}
                  value={cpwd}
                  required
                  className="form-control"
                  placeholder="Confirm Password"
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock"></span>
                  </div>
                </div>
              </div>
              <button
                className="btn btn-primary btn-block"
                onClick={handleSubmit}
              >
                Change password
              </button>
              <p className="mb-1">
                <Link to={"/admin/login"}>Login</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
