import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Swal from "sweetalert2";
import Select from "react-select";
import DatePicker from "react-date-picker";
import moment from "moment/moment";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

import { Post } from "../../../services/api";
import { useLoaderContext } from "../../../context/LoaderContext";
import Loader from "../../Loader";

const AddHoliday = () => {
  const navigate = useNavigate();
  const { isLoading, setLoading } = useLoaderContext();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [date, setDate] = useState(new Date());
  const [status, setStatus] = useState({});
  const statusOptions = [
    { value: "Active", label: "Active" },
    { value: "In Active", label: "In Active" },
  ];

  const [errMsg, setErrMsg] = useState("");
  const [errUnAuthorizedMsg, setErrUnAuthorizedMsg] = useState("");

  useEffect(() => {
    document.title = "Add Holiday";
  }, []);
  useEffect(() => {
    setErrMsg("");
  }, [name, description, date, status]);

  useEffect(() => {
    if (errMsg !== "") {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: errMsg,
      }).then(function () {
        setErrMsg("");
        setLoading(false);
      });
    } else {
      setLoading(false);
    }

    // eslint-disable-next-line
  }, [errMsg]);

  useEffect(() => {
    if (errUnAuthorizedMsg !== "") {
      setLoading(false);
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: errUnAuthorizedMsg,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Go Back",
        cancelButtonText: "Login",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then(function (result) {
        setErrUnAuthorizedMsg("");
        setLoading(false);
        if (result.isConfirmed) {
          navigate(-1);
        } else {
          navigate("/admin/login", { replace: true });
        }
      });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [errUnAuthorizedMsg]);
  const handleSubmit = async () => {
    try {
      setLoading(true);
      setErrMsg("");
      if (name === "") {
        setErrMsg("Name is require.");
        return;
      }
      if (date === "") {
        setErrMsg("Date is require.");
        return;
      }
      if (status.value === "" || status.value === undefined) {
        setErrMsg("Status is require.");
        return;
      }
      let payload = {
        name: name,
        description: description,
        date: moment(date).format("YYYY-MM-DD"),
        status: status.value,
      };
      const response = await Post("admin/addHoliday", payload);
      if (response.status === 401) {
        setLoading(false);
        setErrUnAuthorizedMsg(response.data);
      } else if (response.status === 404) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Api Not Found!",
        }).then(function () {
          navigate("/admin/holidayList", { replace: true });
        });
      } else if (response.hasOwnProperty("error")) {
        setLoading(false);
        setErrMsg(response.data);
      } else {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data,
        }).then(function () {
          navigate("/admin/holidayList", { replace: true });
        });
      }
    } catch (error) {
      setLoading(false);
      setErrMsg(error.message);
    }
  };
  return (
    <>
      {isLoading && <Loader />}
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">Add Holiday</h1>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="card card-primary">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="name">Name</label>
                          <input
                            type="text"
                            id="name"
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                            placeholder="Name"
                            required
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="date">Date</label>
                          <DatePicker
                            id="date"
                            className="form-control"
                            value={date}
                            onChange={setDate}
                            format="yyyy-MM-dd"
                            minDate={new Date()}
                            clearIcon={null}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="description">Description</label>
                          <textarea
                            type="text"
                            id="description"
                            onChange={(e) => setDescription(e.target.value)}
                            value={description}
                            placeholder="Description"
                            className="form-control"
                            rows="3"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="status">Status</label>
                          <Select
                            id="status"
                            options={statusOptions}
                            defaultValue={status}
                            onChange={setStatus}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-md-3 m-2">
                        <button
                          className="btn btn-success btn-block"
                          onClick={handleSubmit}
                        >
                          Add Holiday
                        </button>
                      </div>
                      <div className="col-md-3 m-2">
                        <Link
                          to={"/admin/holidayList"}
                          className="btn btn-danger btn-block"
                        >
                          Cancel
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default AddHoliday;
