import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import Swal from "sweetalert2";
import moment from "moment/moment";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

import { Post, GetProfileImageById } from "../../../services/api";
import { useLoaderContext } from "../../../context/LoaderContext";
import Loader from "../../Loader";

const ViewEmployee = () => {
  const navigate = useNavigate();
  const { isLoading, setLoading } = useLoaderContext();
  const params = useParams();

  const [imageUrl, setImageUrl] = useState("");
  const [employeeUniqueId, setEmployeeUniqueId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [dob, setDob] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [pincode, setPincode] = useState("");
  const [currentDepartment, setCurrentDepartment] = useState("");
  const [gender, setGender] = useState("");
  const [joiningDate, setJoiningDate] = useState("");
  const [anniversaryDate, setAnniversaryDate] = useState("");
  const [emergencyContact, setEmergencyContact] = useState("");
  const [bloodGroup, setBloodGroup] = useState("");

  const [currenPage, setCurrentPage] = useState(1);
  const [currenLimit, setCurrentLimit] = useState(10);
  const [empLeaveData, setEmpLeaveData] = useState([]);

  const [errMsg, setErrMsg] = useState("");
  const [errUnAuthorizedMsg, setErrUnAuthorizedMsg] = useState("");

  useEffect(() => {
    document.title = "View Employee";
  }, []);
  useEffect(() => {
    setErrMsg("");
  }, [
    employeeUniqueId,
    firstName,
    lastName,
    email,
    dob,
    address,
    city,
    state,
    country,
    mobileNo,
    currentDepartment,
    gender,
    joiningDate,
    anniversaryDate,
    bloodGroup,
    emergencyContact,
    pincode,
  ]);

  useEffect(() => {
    if (errMsg !== "") {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: errMsg,
      }).then(function () {
        setErrMsg("");
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [errMsg]);

  useEffect(() => {
    if (errUnAuthorizedMsg !== "") {
      setLoading(false);
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: errUnAuthorizedMsg,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Go Back",
        cancelButtonText: "Login",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then(function (result) {
        setErrUnAuthorizedMsg("");
        setLoading(false);
        if (result.isConfirmed) {
          navigate(-1);
        } else {
          navigate("/login", { replace: true });
        }
      });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [errUnAuthorizedMsg]);

  useEffect(() => {
    async function fetchAPISingleEmployee() {
      try {
        setLoading(true);
        let payload = { id: params.id };
        let response = await Post(
          "employee/getSingleEmployeeWithLeave",
          payload
        );
        if (response.status === 401) {
          setLoading(false);
          setErrUnAuthorizedMsg(response.data);
        } else if (response.status === 404) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Api Not Found!",
          }).then(function () {
            navigate("/employee/employeeList", { replace: true });
          });
        } else if (response.hasOwnProperty("error")) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: response.data,
          }).then(function () {
            window.location.reload();
          });
        } else {
          if (response.data.empData.length > 0) {
            let empData = response.data.empData[0];
            setEmployeeUniqueId(empData.employee_unique_id);
            setFirstName(empData.first_name);
            setLastName(empData.last_name);
            setEmail(empData.email);
            setMobileNo(empData.mobile_no);
            setPincode(empData.pincode);
            setAddress(
              empData.address ? empData.address.replace(/----/g, "'") : ""
            );
            setCity(empData.city);
            setState(empData.state);
            setCountry(empData.country);
            setDob(
              empData.dob === null || empData.dob === ""
                ? ""
                : moment(empData.dob).format("YYYY-MM-DD")
            );
            setGender(empData.gender);
            setCurrentDepartment(empData.department);
            setJoiningDate(
              empData.joining_date === null || empData.joining_date === ""
                ? ""
                : moment(empData.joining_date).format("YYYY-MM-DD")
            );
            setAnniversaryDate(
              empData.anniversary_date === null ||
                empData.anniversary_date === ""
                ? ""
                : moment(empData.anniversary_date).format("YYYY-MM-DD")
            );
            setBloodGroup(empData.blood_group);
            setEmergencyContact(empData.emergency_contact);
          }
          if (response.data.empLeaveData.length > 0) {
            setEmpLeaveData(response.data.empLeaveData);
          }
          let pImgT = await GetProfileImageById(params.id);
          if (!pImgT.hasOwnProperty("error")) {
            if (pImgT) {
              setImageUrl(`data:image/jpeg;base64,${pImgT}`);
            }
          }
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.message,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: "Go Back",
          cancelButtonText: "Reload",
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then(function (result) {
          setLoading(false);
          if (result.isConfirmed) {
            navigate("/employee/employeeList");
          } else {
            window.location.reload();
          }
        });
      }
    }
    fetchAPISingleEmployee();
    // eslint-disable-next-line
  }, [params]);

  const columns = [
    {
      dataField: "date",
      text: "Date",
      sort: true,
      formatter: (cellContent, row) => {
        return (
          moment(row.from_date).format("YYYY-MM-DD") +
          " to " +
          moment(row.to_date).format("YYYY-MM-DD")
        );
      },
    },
    {
      dataField: "description",
      text: "Description",
      formatter: (cellContent, row) => {
        return row.description ? row.description.replace(/----/g, "'") : "";
      },
    },
    { dataField: "leave_type", text: "Type" },
    {
      dataField: "created_at",
      text: "Apply Date",
      formatter: (cellContent, row) => {
        return moment(row.created_at).format("YYYY-MM-DD h:mm:ss A");
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "from_date",
      order: "desc",
    },
  ];
  const pagination = paginationFactory({
    page: currenPage,
    sizePerPage: currenLimit,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      setCurrentPage(sizePerPage);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      setCurrentLimit(page);
    },
  });
  const NoDataIndicator = () => (
    <div className="no-data-indicator">No records found</div>
  );
  return (
    <>
      {isLoading && <Loader />}
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">View Employee</h1>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-3">
                <div className="card card-primary card-outline">
                  <div className="card-body box-profile">
                    <div className="text-center">
                      {imageUrl !== "" ? (
                        <img
                          className="profile-user-img img-fluid img-circle custom-image"
                          alt="User profile"
                          src={imageUrl}
                        ></img>
                      ) : (
                        <i className="fas fa-user custom-icon"></i>
                      )}

                      <h3 className="profile-username text-center">
                        {firstName} {lastName}
                      </h3>
                      <span className="text-muted text-center">
                        {employeeUniqueId}
                      </span>
                      <p className="text-muted text-center">
                        {currentDepartment}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Professional Info</h3>
                  </div>
                  <div className="card-body">
                    <strong>
                      <i className="fa-sharp fa-solid fa-envelope mr-1"></i>
                      &nbsp; Email
                    </strong>
                    <p className="text-muted">{email}</p>
                    <strong>
                      <i className="fa-sharp fa-solid fa-phone mr-1"></i>
                      &nbsp; Mobile
                    </strong>
                    <p className="text-muted">{mobileNo}</p>
                    <strong>
                      <i className="fa-sharp fa-solid fa-phone-volume mr-1"></i>
                      &nbsp; Emergency Contact
                    </strong>
                    <p className="text-muted">{emergencyContact}</p>
                    <strong>
                      <i className="fa-sharp fa-solid fa-clock mr-1"></i>
                      &nbsp; Joining Date
                    </strong>
                    <p className="text-muted">
                      {joiningDate ? joiningDate : "---"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Personal Info</h3>
                  </div>
                  <div className="card-body">
                    <strong>
                      <i className="fa-sharp fa-solid fa-venus-mars mr-1"></i>
                      &nbsp; Gender
                    </strong>
                    <p className="text-muted">{gender}</p>
                    <strong>
                      <i className="fa-sharp fa-solid fa-cake-candles mr-1"></i>
                      &nbsp; Date of Birth
                    </strong>
                    <p className="text-muted">{dob ? dob : "---"}</p>
                    <strong>
                      <i className="fa-duotone fa-cake-candles mr-1"></i>
                      &nbsp; Anniversary
                    </strong>
                    <p className="text-muted">
                      {anniversaryDate ? anniversaryDate : "---"}
                    </p>
                    <strong>
                      <i className="fa-sharp fa-solid fa-hand-holding-droplet mr-1"></i>
                      &nbsp; Blood Group
                    </strong>
                    <p className="text-muted">
                      {bloodGroup ? bloodGroup : "---"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">
                      <i className="fa-sharp fa-solid fa-location-dot mr-1"></i>
                      &nbsp; Address
                    </h3>
                  </div>
                  <div className="card-body">
                    <strong>
                      <i className="fa-sharp fa-solid fa-address-book mr-1"></i>
                      &nbsp; Address
                    </strong>
                    <p className="text-muted">
                      {address},{city},{state},{country},{pincode}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {empLeaveData.length > 0 && (
              <div className="row">
                <div className="col-md-12">
                  <div className="card card-primary">
                    <div className="card-header">
                      <h3 className="card-title">
                        <i className="fa-sharp fa-solid fa-right-left mr-1"></i>
                        &nbsp; Leave Details
                      </h3>
                    </div>
                    <div className="card-body">
                      <ToolkitProvider
                        bootstrap4
                        keyField="id"
                        data={empLeaveData}
                        columns={columns}
                        search
                      >
                        {(props) => (
                          <div className="row">
                            <div className="">
                              <BootstrapTable
                                {...props.baseProps}
                                pagination={pagination}
                                defaultSorted={defaultSorted}
                                noDataIndication={<NoDataIndicator />}
                                striped
                              />
                            </div>
                          </div>
                        )}
                      </ToolkitProvider>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="row">
              <div className="col-md-12">
                <div className="row justify-content-center">
                  <div className="col-md-3 m-2">
                    <Link
                      to={"/employee/employeeList"}
                      className="btn btn-block btn-default"
                    >
                      Back
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default ViewEmployee;
