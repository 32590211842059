import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Swal from "sweetalert2";

import { Get } from "../../services/api";
import { useLoaderContext } from "../../context/LoaderContext";
import Loader from "../Loader";

const AdminDashboard = () => {
  const navigate = useNavigate();
  const { isLoading, setLoading } = useLoaderContext();

  const [totalAdminUser, setTotalAdminUser] = useState(0);
  const [totalEmployee, setTotalEmployee] = useState(0);
  const [totalDepartment, setTotalDepartment] = useState(0);
  const [totalLeaveType, setTotalLeaveType] = useState(0);

  const [errMsg, setErrMsg] = useState("");
  const [errUnAuthorizedMsg, setErrUnAuthorizedMsg] = useState("");

  useEffect(() => {
    document.title = "DashBoard";
  }, []);
  useEffect(() => {
    if (errUnAuthorizedMsg !== "") {
      setLoading(false);
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: errUnAuthorizedMsg,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Go Back",
        cancelButtonText: "Login",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then(function (result) {
        setErrUnAuthorizedMsg("");
        setLoading(false);
        if (result.isConfirmed) {
          window.location.reload();
        } else {
          navigate("/admin/login", { replace: true });
        }
      });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [errUnAuthorizedMsg]);
  useEffect(() => {
    if (errMsg !== "") {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: errMsg,
      }).then(function () {
        setErrMsg("");
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [errMsg]);

  useEffect(() => {
    async function fetchAPIDashboardInfo() {
      try {
        setLoading(true);
        let response = await Get("admin/getDashboardInfo");
        if (response.status === 401) {
          setLoading(false);
          setErrUnAuthorizedMsg(response.data);
        } else if (response.status === 404) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Api Not Found!",
          }).then(function () {
            window.location.reload();
          });
        } else if (response.hasOwnProperty("error")) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: response.data,
          }).then(function () {
            window.location.reload();
          });
        } else {
          setLoading(false);
          setTotalAdminUser(response.data.totalAdminUser);
          setTotalDepartment(response.data.totalDepartment);
          setTotalLeaveType(response.data.totalLeaveType);
          setTotalEmployee(response.data.totalEmployee);
        }
      } catch (error) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.message,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: "Go Back",
          cancelButtonText: "Reload",
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then(function (result) {
          setLoading(false);
          if (result.isConfirmed) {
            navigate(-1);
          } else {
            window.location.reload();
          }
        });
      }
    }
    fetchAPIDashboardInfo();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      {isLoading && <Loader />}
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2 justify-content-between">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">Dashboard</h1>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default color-palette-box">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-3 col-6">
                    <div className="small-box bg-info">
                      <div className="inner">
                        <h3>{totalAdminUser}</h3>
                        <p>Total Admin</p>
                      </div>
                      <div className="icon">
                        <i className="nav-icon fas fa-users" />
                      </div>
                      <Link
                        to={"/admin/adminList"}
                        className="small-box-footer"
                      >
                        More info <i className="fas fa-arrow-circle-right"></i>
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-3 col-6">
                    <div className="small-box bg-info">
                      <div className="inner">
                        <h3>{totalEmployee}</h3>
                        <p>Total Employees</p>
                      </div>
                      <div className="icon">
                        <i className="nav-icon fas fa-users" />
                      </div>
                      <Link
                        to={"/admin/employeeList"}
                        className="small-box-footer"
                      >
                        More info <i className="fas fa-arrow-circle-right"></i>
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-3 col-6">
                    <div className="small-box bg-info">
                      <div className="inner">
                        <h3>{totalDepartment}</h3>
                        <p>Total Department</p>
                      </div>
                      <div className="icon">
                        <i className="nav-icon fas fa-apartment" />
                      </div>
                      <Link
                        to={"/admin/departmentList"}
                        className="small-box-footer"
                      >
                        More info <i className="fas fa-arrow-circle-right"></i>
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-3 col-6">
                    <div className="small-box bg-info">
                      <div className="inner">
                        <h3>{totalLeaveType}</h3>
                        <p>Leave Type</p>
                      </div>
                      <div className="icon">
                        <i className="nav-icon fas" />
                      </div>
                      <Link
                        to={"/admin/leaveTypeList"}
                        className="small-box-footer"
                      >
                        More info <i className="fas fa-arrow-circle-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default AdminDashboard;
