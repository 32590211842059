import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import Swal from "sweetalert2";
import Select from "react-select";
import DatePicker from "react-date-picker";
import moment from "moment/moment";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

import { Post, Get, GetProfileImage } from "../../services/api";
import { useLoaderContext } from "../../context/LoaderContext";
import Loader from "../Loader";

const Profile = () => {
  const navigate = useNavigate();
  const { isLoading, setLoading } = useLoaderContext();
  const params = useParams();

  const [imageUrl, setImageUrl] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [employeeId, setEmployeeId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [dob, setDob] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [pincode, setPincode] = useState("");
  const [joiningDate, setJoiningDate] = useState("");
  const [anniversaryDate, setAnniversaryDate] = useState("");
  const [emergencyContact, setEmergencyContact] = useState("");
  const [bloodGroup, setBloodGroup] = useState({});
  const bloodGroupOptions = [
    { value: "O Positive", label: "O Positive" },
    { value: "O Negative", label: "O Negative" },
    { value: "A Positive", label: "A Positive" },
    { value: "A Negative", label: "A Negative" },
    { value: "B Positive", label: "B Positive" },
    { value: "B Negative", label: "B Negative" },
    { value: "AB Positive", label: "AB Positive" },
    { value: "AB Negative", label: "AB Negative" },
  ];
  const [gender, setGender] = useState({});
  const genderOptions = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Other", label: "Other" },
  ];

  const [errMsg, setErrMsg] = useState("");
  const [errUnAuthorizedMsg, setErrUnAuthorizedMsg] = useState("");

  useEffect(() => {
    document.title = "Edit Profile";
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [
    employeeId,
    firstName,
    lastName,
    email,
    dob,
    address,
    city,
    state,
    country,
    mobileNo,
    gender,
    joiningDate,
    anniversaryDate,
    bloodGroup,
    emergencyContact,
    pincode,
    profileImage,
  ]);

  useEffect(() => {
    if (errMsg !== "") {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: errMsg,
      }).then(function () {
        setErrMsg("");
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [errMsg]);

  useEffect(() => {
    if (errUnAuthorizedMsg !== "") {
      setLoading(false);
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: errUnAuthorizedMsg,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Go Back",
        cancelButtonText: "Login",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then(function (result) {
        setErrUnAuthorizedMsg("");
        setLoading(false);
        if (result.isConfirmed) {
          navigate(-1);
        } else {
          navigate("/login", { replace: true });
        }
      });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [errUnAuthorizedMsg]);

  useEffect(() => {
    async function fetchAPIGetProfile() {
      try {
        setLoading(true);
        let response = await Get("employee/getProfile");
        if (response.status === 401) {
          setLoading(false);
          setErrUnAuthorizedMsg(response.data);
        } else if (response.status === 404) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Api Not Found!",
          }).then(function () {
            navigate("/employee", { replace: true });
          });
        } else if (response.hasOwnProperty("error")) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: response.data,
          }).then(function () {
            window.location.reload();
          });
        } else {
          setEmployeeId(response.data[0].employee_unique_id);
          setFirstName(response.data[0].first_name);
          setLastName(response.data[0].last_name);
          setEmail(response.data[0].email);
          setMobileNo(response.data[0].mobile_no);
          setPincode(response.data[0].pincode);
          setAddress(
            response.data[0].address
              ? response.data[0].address.replace(/----/g, "'")
              : ""
          );
          setCity(response.data[0].city);
          setState(response.data[0].state);
          setCountry(response.data[0].country);
          setDob(
            response.data[0].dob === null || response.data[0].dob === ""
              ? ""
              : new Date(response.data[0].dob)
          );
          setGender({
            label: response.data[0].gender,
            value: response.data[0].gender,
          });
          setJoiningDate(
            response.data[0].joining_date === null ||
              response.data[0].joining_date === ""
              ? ""
              : new Date(response.data[0].joining_date)
          );
          setAnniversaryDate(
            response.data[0].anniversary_date === null ||
              response.data[0].anniversary_date === ""
              ? ""
              : new Date(response.data[0].anniversary_date)
          );
          setBloodGroup(
            response.data[0].blood_group === "" ||
              response.data[0].blood_group === null
              ? ""
              : {
                  value: response.data[0].blood_group,
                  label: response.data[0].blood_group,
                }
          );
          setEmergencyContact(response.data[0].emergency_contact);
          let pImg = await GetProfileImage();
          if (!pImg.hasOwnProperty("error")) {
            if (pImg) {
              setImageUrl(`data:image/jpeg;base64,${pImg}`);
            }
          }
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.message,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: "Go Back",
          cancelButtonText: "Reload",
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then(function (result) {
          setLoading(false);
          if (result.isConfirmed) {
            navigate("/employee");
          } else {
            window.location.reload();
          }
        });
      }
    }
    fetchAPIGetProfile();
    // eslint-disable-next-line
  }, [params]);
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const binaryImage = new Uint8Array(reader.result);
      setProfileImage(binaryImage);
    };
    if (file) {
      reader.readAsArrayBuffer(file);
    }
  };
  const getImagePreviewUrl = () => {
    if (profileImage) {
      const blob = new Blob([profileImage], { type: "image/jpeg" });
      const dataUrl = URL.createObjectURL(blob);
      return dataUrl;
    }
    if (imageUrl) {
      return imageUrl;
    }
    return null;
  };
  const handleSubmit = async () => {
    try {
      setLoading(true);
      setErrMsg("");

      if (firstName === "") {
        setErrMsg("First Name is require.");
        return;
      }
      if (lastName === "") {
        setErrMsg("Last Name is require.");
        return;
      }
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        setErrMsg("Please Enter Valid Email.");
        return;
      }
      if (gender.value === "" || gender.value === undefined) {
        setErrMsg("Gender is require.");
        return;
      }
      if (address === "") {
        setErrMsg("Address is require.");
        return;
      }
      if (city === "") {
        setErrMsg("City is require.");
        return;
      }
      if (state === "") {
        setErrMsg("State is require.");
        return;
      }
      if (country === "") {
        setErrMsg("Country is require.");
        return;
      }
      if (pincode === "") {
        setErrMsg("Pincode is require.");
        return;
      }
      const pincodeRegex = /^[0-9]{6}$/;
      if (!pincodeRegex.test(pincode)) {
        setErrMsg("Please Enter Valid Pincode.");
        return;
      }
      if (dob === "") {
        setErrMsg("Dob is require.");
        return;
      }
      if (mobileNo === "") {
        setErrMsg("Mobile No is require.");
        return;
      }
      const mobileRegex = /^[0-9]{10}$/;
      if (!mobileRegex.test(mobileNo)) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Please Enter Valid Mobile No!",
        });
        setLoading(false);
        return;
      }
      if (emergencyContact === "") {
        setErrMsg("Emergency Contact is require.");
        return;
      }
      const emergencyContactRegex = /^[0-9]{10}$/;
      if (!emergencyContactRegex.test(emergencyContact)) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Please Enter Valid Emergency Contact!",
        });
        setLoading(false);
        return;
      }
      if (joiningDate === "") {
        setErrMsg("Joining Date is require.");
        return;
      }

      let payload = {
        profileImage: profileImage ? Array.from(profileImage) : null,
        employeeId: employeeId,
        firstName: firstName,
        lastName: lastName,
        gender: gender.value,
        dob: dob === null || dob === "" ? "" : moment(dob).format("YYYY-MM-DD"),
        address: address,
        city: city,
        state: state,
        pincode: pincode,
        country: country,
        mobileNo: mobileNo,
        joiningDate:
          joiningDate === null || joiningDate === ""
            ? ""
            : moment(joiningDate).format("YYYY-MM-DD"),
        anniversaryDate:
          anniversaryDate === null || anniversaryDate === ""
            ? ""
            : moment(anniversaryDate).format("YYYY-MM-DD"),
        bloodGroup: bloodGroup.value === undefined ? "" : bloodGroup.value,
        emergencyContact: emergencyContact,
      };
      const response = await Post("employee/updateProfile", payload);
      if (response.status === 401) {
        setLoading(false);
        setErrUnAuthorizedMsg(response.data);
      } else if (response.status === 404) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Api Not Found!",
        }).then(function () {
          navigate("/employee", { replace: true });
        });
      } else if (response.hasOwnProperty("error")) {
        setLoading(false);
        setErrMsg(response.data);
      } else {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data,
        }).then(function () {
          navigate("/employee/profileView", { replace: true });
        });
      }
    } catch (error) {
      setLoading(false);
      setErrMsg(error.message);
    }
  };
  return (
    <>
      {isLoading && <Loader />}
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">Update Profile</h1>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="card card-primary">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="employeeId">Employee Unique Id</label>
                          <input
                            type="text"
                            id="employeeId"
                            value={employeeId}
                            placeholder="Employee Unique Id"
                            disabled
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="profileImage">Profile Image</label>
                          <input
                            type="file"
                            id="profileImage"
                            onChange={handleFileChange}
                            placeholder="Profile Image"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <img
                          src={getImagePreviewUrl()}
                          alt="Profile"
                          height={80}
                          width={80}
                        ></img>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="firstName">First Name *</label>
                          <input
                            type="text"
                            id="firstName"
                            onChange={(e) => setFirstName(e.target.value)}
                            value={firstName}
                            placeholder="First Name"
                            required
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="lastName">Last Name *</label>
                          <input
                            type="text"
                            id="lastName"
                            onChange={(e) => setLastName(e.target.value)}
                            value={lastName}
                            placeholder="Last Name"
                            required
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="email">Email *</label>
                          <input
                            type="email"
                            id="email"
                            value={email}
                            placeholder="Email"
                            disabled
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="mobileNo">Mobile No. *</label>
                          <input
                            type="text"
                            id="mobileNo"
                            onChange={(e) => setMobileNo(e.target.value)}
                            value={mobileNo}
                            placeholder="Mobile No."
                            required
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="gender">Gender *</label>
                          <Select
                            id="gender"
                            options={genderOptions}
                            value={gender}
                            onChange={setGender}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="dob">Date Of Birth *</label>
                          <DatePicker
                            id="dob"
                            className="form-control"
                            value={dob}
                            onChange={setDob}
                            format="yyyy-MM-dd"
                            maxDate={new Date()}
                            clearIcon={null}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="address">Address *</label>
                          <input
                            type="text"
                            id="address"
                            onChange={(e) => setAddress(e.target.value)}
                            value={address}
                            placeholder="Address"
                            required
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="city">City *</label>
                          <input
                            type="text"
                            id="city"
                            onChange={(e) => setCity(e.target.value)}
                            value={city}
                            required
                            placeholder="City"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="state">State *</label>
                          <input
                            type="text"
                            id="state"
                            onChange={(e) => setState(e.target.value)}
                            value={state}
                            placeholder="State"
                            required
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="country">Country *</label>
                          <input
                            type="text"
                            id="country"
                            onChange={(e) => setCountry(e.target.value)}
                            value={country}
                            required
                            placeholder="Country"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="pincode">Pincode *</label>
                          <input
                            type="text"
                            id="pincode"
                            onChange={(e) => setPincode(e.target.value)}
                            value={pincode}
                            placeholder="Pincode"
                            required
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="joiningDate">Joining Date *</label>
                          <DatePicker
                            id="joiningDate"
                            className="form-control"
                            value={joiningDate}
                            onChange={setJoiningDate}
                            format="yyyy-MM-dd"
                            clearIcon={null}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="anniversary">Anniversary Date</label>
                          <DatePicker
                            id="anniversary"
                            className="form-control"
                            value={anniversaryDate}
                            onChange={setAnniversaryDate}
                            format="yyyy-MM-dd"
                            clearIcon={null}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="bloodGroup">Blood Group</label>
                          <Select
                            id="bloodGroup"
                            options={bloodGroupOptions}
                            value={bloodGroup}
                            onChange={setBloodGroup}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="emergencyContact">
                            Emergency Contact *
                          </label>
                          <input
                            type="text"
                            id="emergencyContact"
                            onChange={(e) =>
                              setEmergencyContact(e.target.value)
                            }
                            value={emergencyContact}
                            placeholder="Emergency Contact"
                            required
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-md-3 m-2">
                        <button
                          className="btn btn-success btn-block"
                          onClick={handleSubmit}
                        >
                          Update Profile
                        </button>
                      </div>
                      <div className="col-md-3 m-2">
                        <Link
                          to={"/employee"}
                          className="btn btn-danger btn-block"
                        >
                          Cancel
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default Profile;
