import React,{ useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Swal from "sweetalert2";
import Select from "react-select";
import { Post } from "../../../services/api";
import { useLoaderContext } from "../../../context/LoaderContext";
import Loader from "../../Loader";

const AddDepartment = () => {
  const navigate = useNavigate();
  const { isLoading, setLoading } = useLoaderContext();
  const [departmentName, setDepartmentName] = useState("");
  const [departmentShortName, setDepartmentShortName] = useState("");
  const [departmentCode, setDepartmentCode] = useState("");
  const [status, setStatus] = useState({});
  const [errMsg, setErrMsg] = useState("");
  const [errUnAuthorizedMsg, setErrUnAuthorizedMsg] = useState("");
  const statusOptions = [
    { value: "Active", label: "Active" },
    { value: "In Active", label: "In Active" },
  ];

  useEffect(() => {
    document.title = "Add Department";
  }, []);
  useEffect(() => {
    setErrMsg("");
  }, [departmentName, departmentShortName, departmentCode, status]);

  useEffect(() => {
    if (errMsg !== "") {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: errMsg,
      }).then(function () {
        setErrMsg("");
        setLoading(false);
      });
    } else {
      setLoading(false);
    }

    // eslint-disable-next-line
  }, [errMsg]);

  useEffect(() => {
    if (errUnAuthorizedMsg !== "") {
      setLoading(false);
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: errUnAuthorizedMsg,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Go Back",
        cancelButtonText: "Login",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then(function (result) {
        setErrUnAuthorizedMsg("");
        setLoading(false);
        if (result.isConfirmed) {
          navigate(-1);
        } else {
          navigate("/admin/login", { replace: true });
        }
      });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [errUnAuthorizedMsg]);
  const handleSubmit = async () => {
    try {
      setLoading(true);
      setErrMsg("");
      if (departmentName === "") {
        setErrMsg("Department Name is require.");
        return;
      }
      if (departmentShortName === "") {
        setErrMsg("Department Short Name is require.");
        return;
      }
      if (departmentCode === "") {
        setErrMsg("Department Code is require.");
        return;
      }
      if (status.value === "" || status.value === undefined) {
        setErrMsg("Status is require.");
        return;
      }
      let payload = {
        departmentName: departmentName,
        departmentShortName: departmentShortName,
        departmentCode: departmentCode,
        status: status.value,
      };
      const response = await Post("admin/addDepartment", payload);
      if (response.status === 401) {
        setLoading(false);
        setErrUnAuthorizedMsg(response.data);
      } else if (response.status === 404) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Api Not Found!",
        }).then(function () {
          navigate("/admin/departmentList", { replace: true });
        });
      } else if (response.hasOwnProperty("error")) {
        setLoading(false);
        setErrMsg(response.data);
      } else {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data,
        }).then(function () {
          navigate("/admin/departmentList", { replace: true });
        });
      }
    } catch (error) {
      setLoading(false);
      setErrMsg(error.message);
    }
  };
  return (
    <>
      {isLoading && <Loader />}
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">Add Department</h1>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="card card-primary">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="departmentName">
                            Department Name
                          </label>
                          <input
                            type="text"
                            id="departmentName"
                            onChange={(e) => setDepartmentName(e.target.value)}
                            value={departmentName}
                            placeholder="Department Name"
                            required
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="departmentShortName">
                            Department Short Name
                          </label>
                          <input
                            type="text"
                            id="departmentShortName"
                            onChange={(e) =>
                              setDepartmentShortName(e.target.value)
                            }
                            value={departmentShortName}
                            placeholder="Department Short Name"
                            required
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="departmentCode">
                            Department Code
                          </label>
                          <input
                            type="text"
                            id="departmentCode"
                            onChange={(e) => setDepartmentCode(e.target.value)}
                            value={departmentCode}
                            placeholder="Department Code"
                            required
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="status">Status</label>
                          <Select
                            id="status"
                            options={statusOptions}
                            defaultValue={status}
                            onChange={setStatus}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-md-3 m-2">
                        <button
                          className="btn btn-success btn-block"
                          onClick={handleSubmit}
                        >
                          Add Department
                        </button>
                      </div>
                      <div className="col-md-3 m-2">
                        <Link
                          to={"/admin/departmentList"}
                          className="btn btn-danger btn-block"
                        >
                          Cancel
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default AddDepartment;
