import React from "react";
import { useNavigate, Outlet } from "react-router-dom";
import { withRouter } from "./WithRouter";
import Swal from "sweetalert2";
import { getCurrentUser } from "../services/api";

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

const RequireAuth = ({ userType }) => {
  const user = getCurrentUser();
  const navigate = useNavigate();
  if (user) {
    const decodedJwt = parseJwt(user.accessToken);
    if (userType !== decodedJwt.userType) {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: "Unauthorized Route,Please Login again or go Back.",
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Go Back",
        cancelButtonText: "Login",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then(function (result) {
        if (result.isConfirmed) {
          navigate(-1);
        } else {
          navigate("/login", { replace: true });
        }
      });
    }

    if (decodedJwt.exp * 1000 < Date.now()) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Session Expire,Try Login again.",
      }).then(function () {
        navigate("/login", { replace: true });
      });
    }
  } else {
    Swal.fire({
      icon: "error",
      title: "Error",
      text: "Session Expire,Try Login again.",
    }).then(function () {
      navigate("/login", { replace: true });
    });
  }

  return <Outlet />;
};

export default withRouter(RequireAuth);
