import axios from "axios";
import authHeader from "./auth-header";
const baseURL = process.env.REACT_APP_API_URL + "/api/";

export const Get = async (endpoint) => {
  return axios({
    method: "get",
    url: baseURL + endpoint,
    headers: authHeader(),
  })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return {
        error: true,
        data: err.hasOwnProperty("response")
          ? err.response.data.data
          : err.message,
        status: err.hasOwnProperty("response") ? err.response.status : null,
      };
    });
};
export const Post = async (endpoint, data) => {
  return axios({
    method: "post",
    url: baseURL + endpoint,
    data: {
      payload: data,
    },
    headers: authHeader(),
  })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return {
        error: true,
        data: err.hasOwnProperty("response")
          ? err.response.data.data
          : err.message,
        status: err.hasOwnProperty("response") ? err.response.status : null,
      };
    });
};

export const logout = () => {
  localStorage.clear();
};
export const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

export const GetProfileImage = async () => {
  return fetch(process.env.REACT_APP_API_URL + "/api/profileImages", {
    headers: authHeader(),
  })
    .then((response) => response.arrayBuffer())
    .then((buffer) => {
      // Convert ArrayBuffer to base64 string
      const base64Data = btoa(
        new Uint8Array(buffer).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ""
        )
      );
      return base64Data;
    })
    .catch((error) => {
      console.error("Error fetching image data:", error);
    });
};
export const GetProfileImageById = async (id) => {
  return fetch(process.env.REACT_APP_API_URL + "/api/profileImagesById", {
    method: "POST",
    headers: {
      "x-access-token": authHeader()["x-access-token"],
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ id: id }),
  })
    .then((response) => response.arrayBuffer())
    .then((buffer) => {
      // Convert ArrayBuffer to base64 string
      const base64Data = btoa(
        new Uint8Array(buffer).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ""
        )
      );
      return base64Data;
    })
    .catch((error) => {
      console.error("Error fetching image data:", error);
    });
};

export const GetPdfFile = async (endpoint, data) => {
  return axios({
    method: "post",
    url: baseURL + endpoint,
    data: {
      payload: data,
    },
    headers: {
      "x-access-token": authHeader()["x-access-token"],
      responseType: "json",
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return {
        error: true,
        data: err.hasOwnProperty("response")
          ? err.response.data.data
          : err.message,
        status: err.hasOwnProperty("response") ? err.response.status : null,
      };
    });
};
