import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useLoaderContext } from "../../../context/LoaderContext";
import Loader from "../../Loader";
import { Post, logout } from "../../../services/api";

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoading, setLoading } = useLoaderContext();
  const [errUnAuthorizedMsg, setErrUnAuthorizedMsg] = useState("");
  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    document.title = "Login";
    logout();
  }, []);
  useEffect(() => {
    if (errUnAuthorizedMsg !== "") {
      setLoading(false);
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: errUnAuthorizedMsg,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Go Back",
        cancelButtonText: "Login",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then(function (result) {
        setErrUnAuthorizedMsg("");
        setLoading(false);
        if (result.isConfirmed) {
          navigate(-1);
        } else {
          navigate("/admin/login", { replace: true });
        }
      });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [errUnAuthorizedMsg]);
  useEffect(() => {
    setErrMsg("");
  }, [email, pwd]);

  useEffect(() => {
    if (errMsg !== "") {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: errMsg,
      }).then(function () {
        setErrMsg("");
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [errMsg]);
  const handleSubmit = async () => {
    try {
      setLoading(true);
      if (email === "") {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Email is Required!",
        });
        setLoading(false);
        return;
      }
      if (pwd === "") {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Password is Required!",
        });
        setLoading(false);
        return;
      }
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Please Enter Valid Email!",
        });
        setLoading(false);
        return;
      }
      let payload = {
        email: email,
        password: pwd,
      };
      const response = await Post("admin/login", payload);
      if (response.status === 401) {
        setLoading(false);
        setErrUnAuthorizedMsg(response.data);
      } else if (response.status === 404) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Api Not Found!",
        }).then(function () {
          navigate("/admin/login", { replace: true });
        });
      } else if (response.hasOwnProperty("error")) {
        setLoading(false);
        setErrMsg(response.data);
      } else {
        setLoading(false);
        const accessToken = response.data.accessToken;
        const userName = response.data.userName;
        localStorage.setItem(
          "user",
          JSON.stringify({
            accessToken: accessToken,
            userName: userName,
            LoggedIn: true,
          })
        );
        setEmail("");
        setPwd("");
        const from = location.state?.from?.pathname || "/admin";
        navigate(from, { replace: true });
        window.location.reload(true);
      }
    } catch (error) {
      setLoading(false);
      setErrMsg(error.message);
      localStorage.clear();
    }
  };

  return (
    <>
      {isLoading && <Loader />}

      <div className="login-page">
        <div className="login-box">
          <div className="card card-outline card-primary">
            <div className="card-header text-center">
              <h4>
                <b>CoreFragment Admin</b>
              </h4>
            </div>
            <div className="card-body">
              <p className="login-box-msg">Sign in to start your session</p>

              <div className="input-group mb-3">
                <input
                  type="email"
                  id="email"
                  autoComplete="off"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  placeholder="Email"
                  required
                  className="form-control"
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-envelope"></span>
                  </div>
                </div>
              </div>
              <div className="input-group mb-3">
                <input
                  type="password"
                  id="password"
                  onChange={(e) => setPwd(e.target.value)}
                  value={pwd}
                  required
                  className="form-control"
                  placeholder="Password"
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock"></span>
                  </div>
                </div>
              </div>
              <button
                className="btn btn-primary btn-block"
                onClick={handleSubmit}
              >
                Sign In
              </button>

              <p className="mb-1">
                <Link to={"/admin/forgotPassword"}>Forgot password?</Link>
              </p>
              <p className="mb-1">
                <Link to={"/"}>Login As Employee</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
