import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Swal from "sweetalert2";
import moment from "moment/moment";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

import { Get, Post } from "../../../services/api";
import { useLoaderContext } from "../../../context/LoaderContext";
import Loader from "../../Loader";

const LeaveList = () => {
  const { SearchBar } = Search;
  const navigate = useNavigate();
  const { isLoading, setLoading } = useLoaderContext();
  const [currenPage, setCurrentPage] = useState(1);
  const [currenLimit, setCurrentLimit] = useState(10);
  const [currenData, setCurrentData] = useState([]);
  const [errUnAuthorizedMsg, setErrUnAuthorizedMsg] = useState("");

  useEffect(() => {
    document.title = "Leave List";
  }, []);
  useEffect(() => {
    if (errUnAuthorizedMsg !== "") {
      setLoading(false);
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: errUnAuthorizedMsg,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Go Back",
        cancelButtonText: "Login",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then(function (result) {
        setErrUnAuthorizedMsg("");
        setLoading(false);
        if (result.isConfirmed) {
          navigate(-1);
        } else {
          navigate("/login", { replace: true });
        }
      });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [errUnAuthorizedMsg]);

  useEffect(() => {
    async function fetchAPILeaveList() {
      try {
        setLoading(true);
        let response = await Get("employee/getLeaveList");
        if (response.status === 401) {
          setLoading(false);
          setErrUnAuthorizedMsg(response.data);
        } else if (response.status === 404) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Api Not Found!",
          }).then(function () {
            navigate("/employee", { replace: true });
          });
        } else if (response.hasOwnProperty("error")) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: response.data,
          }).then(function () {
            window.location.reload();
          });
        } else {
          setLoading(false);
          setCurrentData(response.data);
        }
      } catch (error) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.message,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: "Go Back",
          cancelButtonText: "Reload",
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then(function (result) {
          setLoading(false);
          if (result.isConfirmed) {
            navigate("/employee");
          } else {
            window.location.reload();
          }
        });
      }
    }
    fetchAPILeaveList();
    // eslint-disable-next-line
  }, []);
  const columns = [
    {
      dataField: "date",
      text: "Date",
      sort: true,
      formatter: (cellContent, row) => {
        return (
          moment(row.from_date).format("YYYY-MM-DD") +
          " to " +
          moment(row.to_date).format("YYYY-MM-DD")
        );
      },
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
      formatter: (cellContent, row) => {
        return row.description ? row.description.replace(/----/g, "'") : "";
      },
    },
    { dataField: "leave_type", text: "Type", sort: true },
    {
      dataField: "created_at",
      text: "Apply Date",
      sort: true,
      formatter: (cellContent, row) => {
        return moment(row.created_at).format("YYYY-MM-DD h:mm:ss A");
      },
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: (cellContent, row) => {
        if (row.status === "Approved") {
          return <div className="greenColor">{row.status}</div>;
        } else if (row.status === "Not Approved") {
          return <div className="redColor">{row.status}</div>;
        } else {
          return <div className="blueColor">{row.status}</div>;
        }
      },
    },
    {
      dataField: "Action",
      text: "Action",
      classes: "text-center",
      formatter: (cellContent, row) => {
        if (row.status === "Waiting for Approval") {
          return (
            <div className="">
              <button
                className="btn btn-warning"
                onClick={() => handleEdit(row)}
              >
                <i className="fas fa-edit"></i>
              </button>
              <button className="btn btn-info" onClick={() => handleView(row)}>
                <i className="fas fa-eye"></i>
              </button>
              <button
                className="btn btn-danger"
                onClick={() => handleDelete(row)}
              >
                <i className="fas fa-trash"></i>
              </button>
            </div>
          );
        } else {
          return (
            <button className="btn btn-info" onClick={() => handleView(row)}>
              <i className="fas fa-eye"></i>
            </button>
          );
        }
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "created_at",
      order: "desc",
    },
  ];
  const pagination = paginationFactory({
    page: currenPage,
    sizePerPage: currenLimit,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      setCurrentPage(sizePerPage);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      setCurrentLimit(page);
    },
  });

  const handleEdit = (row) => {
    navigate("/employee/updateLeave/" + row.id, { replace: true });
  };
  const handleView = (row) => {
    navigate("/employee/viewLeave/" + row.id, { replace: true });
  };
  const handleDelete = (row) => {
    Swal.fire({
      icon: "warning",
      title: "Warning",
      text: "Are you sure you want to delete?",
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then(async function (result) {
      if (result.isConfirmed) {
        try {
          setLoading(true);
          let payload = { id: row.id };
          let response = await Post("employee/deleteLeave", payload);
          if (response.status === 401) {
            setLoading(false);
            setErrUnAuthorizedMsg(response.data);
          } else if (response.status === 404) {
            setLoading(false);
            Swal.fire({
              icon: "error",
              title: "Error",
              text: "Api Not Found!",
            }).then(function () {
              navigate("/employee", { replace: true });
            });
          } else if (response.hasOwnProperty("error")) {
            setLoading(false);
            Swal.fire({
              icon: "error",
              title: "Error",
              text: response.data,
            }).then(function () {
              window.location.reload();
            });
          } else {
            window.location.reload();
          }
        } catch (error) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: error.message,
          }).then(function () {
            window.location.reload();
          });
        }
      } else {
        window.location.reload();
      }
    });
  };
  const NoDataIndicator = () => (
    <div className="no-data-indicator">No records found</div>
  );
  return (
    <>
      {isLoading && <Loader />}
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">Leave List</h1>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <ToolkitProvider
                      bootstrap4
                      keyField="id"
                      data={currenData}
                      columns={columns}
                      search
                    >
                      {(props) => (
                        <div className="row">
                          <div className="col-sm-4">
                            <SearchBar {...props.searchProps} />
                          </div>
                          <div className="col-sm-8 text-right">
                            <Link
                              to={"/employee/addLeave"}
                              className="btn btn-success m-1"
                            >
                              Add Leave
                            </Link>
                          </div>
                          <div className="">
                            <BootstrapTable
                              {...props.baseProps}
                              pagination={pagination}
                              defaultSorted={defaultSorted}
                              noDataIndication={<NoDataIndicator />}
                              striped
                            />
                          </div>
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default LeaveList;
