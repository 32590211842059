import React from "react";

const Loader = () => {
  return (
    <div className="loader-container">
      <div className="overlay d-flex justify-content-center align-items-center">
        <i
          className="fas fa-spinner fa-pulse fa-10x"
          style={{ color: "#007bff" }}
        ></i>
      </div>
    </div>
  );
};

export default Loader;
