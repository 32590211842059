import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../logo.png";
import { getCurrentUser, GetProfileImage } from "../../services/api";

const Sidebar = () => {
  const location = useLocation();
  const [currentLocation, setCurrentLocation] = useState({});
  const [user, setUser] = useState({});
  const [profileImage, setProfileImage] = useState();
  const currentId = location.pathname.split("/").pop();
  useEffect(() => {
    setUser(getCurrentUser());
    setCurrentLocation(location.pathname);
    async function fetchAPIGetProfileImage() {
      let pImg = await GetProfileImage();
      if (!pImg.hasOwnProperty("error")) {
        if (pImg) {
          setProfileImage(`data:image/jpeg;base64,${pImg}`);
        } else {
          setProfileImage(logo);
        }
      } else {
        setProfileImage(logo);
      }
    }
    fetchAPIGetProfileImage();

    // eslint-disable-next-line
  }, [location]);
  return (
    <aside
      className="main-sidebar sidebar-dark-primary elevation-4"
      id="sidebar"
    >
      <div className="brand-link">
        <img
          src={profileImage}
          className="brand-image img-circle elevation-2"
          alt="employee"
          style={{ height: "33px", width: "44px" }}
        />
        <span
          className="brand-text font-weight-light"
          style={{ color: "#fff" }}
        >
          {user?.userName || "username"}
        </span>
      </div>

      <div className="sidebar">
        <div className="os-padding">
          <div
            className="os-viewport os-viewport-native-scrollbars-invisible"
            style={{ overflowY: "scroll" }}
          >
            <div className="os-content">
              <nav className="mt-2">
                <ul
                  className="nav nav-pills nav-sidebar flex-column"
                  data-widget="treeview"
                  role="menu"
                  data-accordion="false"
                >
                  <li className="nav-item">
                    <Link
                      to={"/employee"}
                      className={`nav-link ${
                        currentLocation === "/employee" ? "active" : ""
                      }`}
                    >
                      <i className="nav-icon fas fa-tachometer-alt" />
                      <p>DashBoard</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to={"/employee/holidayList"}
                      className={`nav-link ${
                        currentLocation === "/employee/holidayList" ||
                        currentLocation === "/employee/viewHoliday/" + currentId
                          ? "active"
                          : ""
                      }`}
                    >
                      <i className="nav-icon fas fa-gift" />
                      <p>Holiday</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to={"/employee/employeeList"}
                      className={`nav-link ${
                        currentLocation === "/employee/employeeList" ||
                        currentLocation ===
                          "/employee/viewEmployee/" + currentId
                          ? "active"
                          : ""
                      }`}
                    >
                      <i className="nav-icon fas fa-users" />
                      <p>Employee</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to={"/employee/leaveList"}
                      className={`nav-link ${
                        currentLocation === "/employee/addLeave" ||
                        currentLocation === "/employee/leaveList" ||
                        currentLocation === "/employee/viewLeave/" + currentId
                          ? "active"
                          : ""
                      }`}
                    >
                      <i className="nav-icon fas fa-right-left" />
                      <p>Leave</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to={"/employee/timeSheetList"}
                      className={`nav-link ${
                        currentLocation === "/employee/timeSheetList"
                          ? "active"
                          : ""
                      }`}
                    >
                      <i className="nav-icon fas fa-file-lines" />
                      <p>TimeSheet</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to={"/employee/salarySlip"}
                      className={`nav-link ${
                        currentLocation === "/employee/salarySlip"
                          ? "active"
                          : ""
                      }`}
                    >
                      <i className="nav-icon fas fa-file-invoice-dollar" />
                      <p>Salary Slip</p>
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
