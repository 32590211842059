import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Select from "react-select";

import { Post, Get } from "../../../services/api";
import { useLoaderContext } from "../../../context/LoaderContext";
import Loader from "../../Loader";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

const BalanceLeave = () => {
  const navigate = useNavigate();
  const { isLoading, setLoading } = useLoaderContext();

  const [employeeList, setEmployeeList] = useState([]);
  const [currentEmployee, setCurrentEmployee] = useState({});
  const [leaveTypeList, setLeaveTypeList] = useState([]);
  const [allLeaveType, setAllLeaveType] = useState([]);
  const [currenLeaveType, setCurrenLeaveType] = useState({});
  const [addCount, setAddCount] = useState(0.0);

  const [errMsg, setErrMsg] = useState("");
  const [errUnAuthorizedMsg, setErrUnAuthorizedMsg] = useState("");

  useEffect(() => {
    document.title = "Balance Leave";
  }, []);
  useEffect(() => {
    setErrMsg("");
  }, [currentEmployee, currenLeaveType, addCount]);

  useEffect(() => {
    if (errMsg !== "") {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: errMsg,
      }).then(function () {
        setErrMsg("");
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [errMsg]);

  useEffect(() => {
    if (errUnAuthorizedMsg !== "") {
      setLoading(false);
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: errUnAuthorizedMsg,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Go Back",
        cancelButtonText: "Login",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then(function (result) {
        setErrUnAuthorizedMsg("");
        setLoading(false);
        if (result.isConfirmed) {
          navigate(-1);
        } else {
          navigate("/admin/login", { replace: true });
        }
      });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [errUnAuthorizedMsg]);
  useEffect(() => {
    async function fetchAPILeaveTypeListForEmployee() {
      try {
        setLoading(true);
        let response = await Get("admin/getLeaveTypeListForEmployee");
        if (response.status === 401) {
          setLoading(false);
          setErrUnAuthorizedMsg(response.data);
        } else if (response.status === 404) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Api Not Found!",
          }).then(function () {
            navigate("/admin", { replace: true });
          });
        } else if (response.hasOwnProperty("error")) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: response.data,
          }).then(function () {
            window.location.reload();
          });
        } else {
          setLoading(false);
          // eslint-disable-next-line
          response.data.map((items) => {
            setAllLeaveType((allLeaveType) => [
              ...allLeaveType,
              {
                label: items.leave_type,
                value: items.id,
              },
            ]);
          });
        }
      } catch (error) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.message,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: "Go Back",
          cancelButtonText: "Reload",
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then(function (result) {
          setLoading(false);
          if (result.isConfirmed) {
            navigate("/admin");
          } else {
            window.location.reload();
          }
        });
      }
    }
    fetchAPILeaveTypeListForEmployee();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    async function fetchAPIEmployeesListForLeave() {
      try {
        setLoading(true);
        const response = await Get("admin/getEmployeesListForLeave");
        if (response.status === 401) {
          setLoading(false);
          setErrUnAuthorizedMsg(response.data);
        } else if (response.status === 404) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Api Not Found!",
          }).then(function () {
            navigate("/admin", { replace: true });
          });
        } else if (response.hasOwnProperty("error")) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: response.data,
          }).then(function () {
            window.location.reload();
          });
        } else {
          setLoading(false);
          // eslint-disable-next-line
          response.data.map((items) => {
            setEmployeeList((employeeList) => [
              ...employeeList,
              {
                label:
                  items.first_name +
                  " " +
                  items.last_name +
                  " - " +
                  items.employee_unique_id,
                value: items.id,
              },
            ]);
          });
        }
      } catch (error) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.message,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: "Go Back",
          cancelButtonText: "Reload",
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then(function (result) {
          setLoading(false);
          if (result.isConfirmed) {
            navigate("/admin");
          } else {
            window.location.reload();
          }
        });
      }
    }
    fetchAPIEmployeesListForLeave();
    // eslint-disable-next-line
  }, []);

  const getBalanceLeave = async (e) => {
    try {
      setLeaveTypeList([]);
      setLoading(true);
      let payload = {
        employeeId: e.value,
      };
      const response = await Post("admin/getBalanceLeaveList", payload);
      if (response.status === 401) {
        setLoading(false);
        setErrUnAuthorizedMsg(response.data);
      } else if (response.status === 404) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Api Not Found!",
        }).then(function () {
          navigate("/admin", { replace: true });
        });
      } else if (response.hasOwnProperty("error")) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: response.data,
        }).then(function () {
          window.location.reload();
        });
      } else {
        setLoading(false);
        // eslint-disable-next-line
        setLeaveTypeList(response.data);

        setCurrentEmployee(e);
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.message,
      }).then(function () {
        setLoading(false);
        window.location.reload();
      });
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      if (currentEmployee.value === "" || currentEmployee.value === undefined) {
        setErrMsg("Employee is require.");
        return;
      }
      if (currenLeaveType.value === "" || currenLeaveType.value === undefined) {
        setErrMsg("Leave Type is require.");
        return;
      }
      if (addCount <= 0) {
        setErrMsg("Add Count Should Be greater Than Zero.");
        return;
      }
      let payload = {
        employeeId: currentEmployee.value,
        leaveTypeId: currenLeaveType.value,
        addCount: addCount,
      };
      const response = await Post("admin/addEmployeeLeaveCount", payload);
      if (response.status === 401) {
        setLoading(false);
        setErrUnAuthorizedMsg(response.data);
      } else if (response.status === 404) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Api Not Found!",
        }).then(function () {
          navigate("/admin", { replace: true });
        });
      } else if (response.hasOwnProperty("error")) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: response.data,
        }).then(function () {
          window.location.reload();
        });
      } else {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data,
        }).then(function () {
          window.location.reload();
        });
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.message,
      }).then(function () {
        setLoading(false);
        window.location.reload();
      });
    }
  };
  const handleAddCount = (e) => {
    const value = e.target.value;
    if (/^-?\d*\.?\d*$/.test(value)) {
      setAddCount(value);
    }
  };
  return (
    <>
      {isLoading && <Loader />}
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">Balance Leave</h1>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="card card-primary">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="employee">Employee</label>
                          <Select
                            id="employee"
                            options={employeeList}
                            value={currentEmployee}
                            onChange={(e) => getBalanceLeave(e)}
                          />
                        </div>
                      </div>
                    </div>
                    {leaveTypeList && leaveTypeList.length > 0 && (
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="card card-default">
                            <div className="card-body">
                              <div className="row">
                                {leaveTypeList.map((items, index) => (
                                  <div className="col-sm-3" key={index}>
                                    <div className="info-box bg-light">
                                      <div className="info-box-content">
                                        <span className="info-box-number text-muted">
                                          {items.leave_type}
                                        </span>
                                        <span className="info-box-text text-muted">
                                          Taken :{" "}
                                          {items.pending_leave +
                                            items.taken_leave}
                                        </span>
                                        <span className="info-box-text text-muted">
                                          Balance :{" "}
                                          {items.total_leave -
                                            items.pending_leave -
                                            items.taken_leave}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {currentEmployee.hasOwnProperty("value") && (
                      <div className="row ">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="leaveType">Leave Type</label>
                            <Select
                              id="leaveType"
                              options={allLeaveType}
                              value={currenLeaveType}
                              onChange={setCurrenLeaveType}
                            />
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="form-group">
                            <label htmlFor="addcount">Add Count</label>
                            <input
                              type="text"
                              id="addcount"
                              onChange={handleAddCount}
                              value={addCount}
                              placeholder="Total leave"
                              required
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-3 mt-3">
                          <button
                            className="btn btn-success btn-block"
                            onClick={handleSubmit}
                          >
                            Add Leave Count
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default BalanceLeave;
